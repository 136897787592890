import { PV } from "@formulajs/formulajs";
import FinancingOptionSegment from "./types/FinancingOptionSegment";

function roundedDollarAmount(value: number | string): number {
  if (typeof value !== "number" && isNaN(parseFloat(value))) {
    throw new TypeError(
      "Invalid value provided. Expected a number or a value that can be converted to a number."
    );
  }
  if (typeof value === "string") {
    value = parseFloat(value);
  }
  return Math.round(value * 100) / 100;
}

function periodicInterest(
  presentValue: number,
  periodicInterestRate: number
): number {
  return roundedDollarAmount(presentValue * periodicInterestRate);
}

function periodicInterestRate(
  interestRate: number,
  paymentsPerYear: number
): number {
  return interestRate / 100 / paymentsPerYear;
}

function presentValueInterestFactor(
  periodicInterestRate: number,
  termLength: number
): number {
  return Math.pow(1 + periodicInterestRate, termLength);
}

function amortizedPayment(
  periodicInterestRate: number,
  termLength: number,
  totalFinanceAmount: number,
  type: string,
  residualAmount: number = 0
): number {
  let payment: number;

  if (periodicInterestRate) {
    const pvif: number = presentValueInterestFactor(
      periodicInterestRate,
      termLength
    );

    if (type == "arrears") {
      payment =
        (periodicInterestRate / (pvif - 1)) *
        (totalFinanceAmount * pvif - residualAmount);
    } else {
      payment =
        (totalFinanceAmount * periodicInterestRate -
          residualAmount * (periodicInterestRate / pvif)) /
        ((1 - 1 / pvif) * (1 + periodicInterestRate));
    }
    return roundedDollarAmount(payment);
  } else if (termLength) {
    payment = (totalFinanceAmount - residualAmount) / termLength;
    return roundedDollarAmount(payment);
  }

  return 0;
}

function calculateTotalFinanceAmount(
  periodicInterestRate: number,
  termLength: number,
  payment: number,
  type?: string,
  residualAmount: number = 0
): number {
  if (!type) type = "arrears";

  let totalFinanceAmount: number;
  let pvif: number;

  if (periodicInterestRate == 0) {
    return payment * termLength + residualAmount;
  }

  if (type == "arrears") {
    pvif = presentValueInterestFactor(periodicInterestRate, termLength);
    totalFinanceAmount =
      (payment / periodicInterestRate) * (1 - 1 / pvif) + residualAmount / pvif;
  } else if (type == "advance") {
    pvif = presentValueInterestFactor(periodicInterestRate, termLength - 1);
    totalFinanceAmount =
      (payment / periodicInterestRate) * (1 - 1 / pvif) +
      parseFloat(payment.toString()) +
      residualAmount / (pvif * (1 + periodicInterestRate));
  }

  return roundedDollarAmount(totalFinanceAmount);
}

export const PAYMENTS_PER_YEAR: number = 12;

function calculatePaymentAndDiscount(
  totalFinanceAmount: number,
  buyRate: number,
  sellRate: number,
  termLength: number,
  paymentType: string,
  termSegments: FinancingOptionSegment[] = [],
  residualAmount: number = 0
): {
  schedule: (number | string)[][];
  payment: number;
  discount: number;
  buyPresentValue: number;
  spread: number;
} {
  const { payment, schedule } = computeAmortizationSchedule(
    null,
    totalFinanceAmount,
    sellRate,
    termLength,
    paymentType,
    termSegments,
    residualAmount
  );

  // Get the total number of custom segment payments
  const termSegmentsPaymentCount: number = termSegments.reduce(
    (total: number, termSegment: FinancingOptionSegment) => {
      return total + termSegment.attributes.count;
    },
    0
  );

  const baseTermLength = termLength - termSegmentsPaymentCount;

  const presentValueAfterCustomSegmentsPlusOne =
    paymentType === "advance"
      ? (payment / periodicInterestRate(buyRate, PAYMENTS_PER_YEAR)) *
          (1 -
            1 /
              Math.pow(
                1 + periodicInterestRate(buyRate, PAYMENTS_PER_YEAR),
                baseTermLength + 1
              )) *
          (1 + periodicInterestRate(buyRate, PAYMENTS_PER_YEAR)) -
        payment
      : (payment / periodicInterestRate(buyRate, PAYMENTS_PER_YEAR)) *
        (1 -
          1 /
            Math.pow(
              1 + periodicInterestRate(buyRate, PAYMENTS_PER_YEAR),
              baseTermLength
            ));

  let termSegmentsPresentValue = 0;

  termSegments.forEach((segment) => {
    const { count, value } = segment.attributes;

    const presentValue = PV(
      periodicInterestRate(buyRate, PAYMENTS_PER_YEAR),
      -count,
      value,
      0,
      paymentType === "advance" ? undefined : 0
    );

    if (presentValue instanceof Error) {
      throw presentValue;
    }
    termSegmentsPresentValue += presentValue;
  });

  const buyPresentValue =
    paymentType === "advance"
      ? roundedDollarAmount(
          (presentValueAfterCustomSegmentsPlusOne + termSegmentsPresentValue) /
            Math.pow(
              1 + periodicInterestRate(buyRate, PAYMENTS_PER_YEAR),
              termSegmentsPaymentCount - 1
            ) +
            residualAmount /
              Math.pow(
                1 + periodicInterestRate(buyRate, PAYMENTS_PER_YEAR),
                termLength
              )
        )
      : roundedDollarAmount(
          (presentValueAfterCustomSegmentsPlusOne + termSegmentsPresentValue) /
            Math.pow(
              1 + periodicInterestRate(buyRate, PAYMENTS_PER_YEAR),
              termSegmentsPaymentCount
            ) +
            residualAmount /
              Math.pow(
                1 + periodicInterestRate(buyRate, PAYMENTS_PER_YEAR),
                termLength
              )
        );

  const discount = roundedDollarAmount(totalFinanceAmount - buyPresentValue);
  const spread = roundedDollarAmount(buyPresentValue - totalFinanceAmount);

  return { schedule, payment, discount, buyPresentValue, spread };
}

function computeAmortizationSchedule(
  payment: number | string,
  totalFinanceAmount: number,
  interestRate: number,
  termLength: number,
  paymentType: string,
  termSegments: FinancingOptionSegment[] = [],
  residualAmount: number = 0
): {
  schedule: (number | string)[][];
  payment: number;
  calculatedTotalFinanceAmount: number;
} {
  // Gather the total amount of all custom segment payments
  const termSegementsTotalAmount: number = termSegments.reduce(
    (total, termSegment) =>
      total +
      termSegment.attributes.count * Number(termSegment.attributes.value),
    0
  );
  // Get the total number of custom segment payments
  const termSegmentsPaymentCount: number = termSegments.reduce(
    (total: number, termSegment: FinancingOptionSegment) => {
      return total + termSegment.attributes.count;
    },
    0
  );
  // number of payments in remaining amount amortization
  const numberOfPayments: number = termLength - termSegmentsPaymentCount;

  // the balance of the loan over time, starting with the total amount financed
  let balance: number = totalFinanceAmount || termSegementsTotalAmount;

  const schedule: (number | string)[][] = [];
  let calculatedTotalFinanceAmount: number;

  // payment exists, so solve for the total finance amount
  if (payment && parseFloat(payment.toString())) {
    calculatedTotalFinanceAmount =
      calculateTotalFinanceAmount(
        periodicInterestRate(interestRate, PAYMENTS_PER_YEAR),
        numberOfPayments,
        parseFloat(payment.toString()),
        paymentType,
        residualAmount
      ) + termSegementsTotalAmount;
  }

  // Push the first heading row into the amortization schedule table array
  const firstRow: (number | string)[] = ["Loan", "", "", "", balance];
  schedule.push(firstRow);

  // account for the remaining interest amount
  // after custom segments have been scheduled
  let remainingInterest: number = 0;

  /************************
    TERM_SEGMENT_HANDLING
   ************************/
  if (termSegments.length) {
    let paymentNumber: number = 1;

    termSegments.forEach((termSegment, segmentsIndex) => {
      const { count, value } = termSegment.attributes;

      for (let i = 0; i < count; i++) {
        const interest: number = periodicInterest(
          balance,
          periodicInterestRate(interestRate, PAYMENTS_PER_YEAR)
        );

        const paymentAmount: number = parseFloat(value.toString());
        let principal: number, row: (number | string)[];

        // advance payments do not accrue interest on the first cycle
        if (segmentsIndex === 0 && i === 0 && paymentType === "advance") {
          principal = roundedDollarAmount(paymentAmount);
          balance = roundedDollarAmount(balance - principal);

          row = [paymentNumber, paymentAmount, "0", principal, balance];
        } else {
          principal = roundedDollarAmount(paymentAmount - interest);
          balance = roundedDollarAmount(balance - principal);

          row = [paymentNumber, paymentAmount, interest, principal, balance];
        }

        schedule.push(row);
        paymentNumber++;

        if (
          i == count - 1 &&
          paymentType == "advance" &&
          segmentsIndex == termSegments.length - 1
        ) {
          // for last custom segment, account for interest in remaining amortized amount, if payments in advance
          balance = roundedDollarAmount(balance + interest);

          remainingInterest = interest;
        }
      }
    });
  }

  let calculatedTotalFinanceAmountRemaining: number = roundedDollarAmount(
    balance - remainingInterest
  );

  if (payment && parseFloat(payment.toString())) {
    calculatedTotalFinanceAmountRemaining = roundedDollarAmount(
      calculatedTotalFinanceAmount - balance
    );
  } else {
    payment = amortizedPayment(
      periodicInterestRate(interestRate, PAYMENTS_PER_YEAR),
      numberOfPayments,
      calculatedTotalFinanceAmountRemaining,
      termSegments.length ? "arrears" : paymentType,
      residualAmount
    );
  }

  for (let i = 1; i <= numberOfPayments; i++) {
    let interest: number;

    const paymentNumber: number = i + termSegmentsPaymentCount;

    if (paymentType === "advance" && i === 1 && !termSegments.length) {
      interest = 0;
    } else {
      interest = periodicInterest(
        calculatedTotalFinanceAmountRemaining,
        periodicInterestRate(interestRate, PAYMENTS_PER_YEAR)
      );
    }

    const principal: number = roundedDollarAmount(
      parseFloat(payment.toString()) - interest
    );

    const formattedInterest: number = interest > 0 ? interest : 0;

    balance = roundedDollarAmount(
      calculatedTotalFinanceAmountRemaining - principal
    );

    calculatedTotalFinanceAmountRemaining = roundedDollarAmount(
      calculatedTotalFinanceAmountRemaining - principal
    );

    // Add residual amount to the last payment
    if (i === numberOfPayments) {
      balance = roundedDollarAmount(balance + residualAmount);
    }

    const row: (number | string)[] = amortizationScheduleRow(
      paymentNumber,
      parseFloat(payment.toString()),
      formattedInterest,
      principal,
      balance
    );

    schedule.push(row);
  }

  return {
    schedule,
    payment: parseFloat(payment.toString()),
    calculatedTotalFinanceAmount,
  };
}

function amortizationScheduleRow(
  paymentNumber: number,
  payment: number,
  formattedInterest: number,
  principal: number,
  balance: number
): (number | string)[] {
  return [paymentNumber, payment, formattedInterest, principal, balance];
}

function calculateInterestRate(
  totalFinanceAmount: number,
  basePayment: number,
  termLength: number,
  paymentType: string,
  termSegments: FinancingOptionSegment[] = [],
  initialGuess: number = 5, // Initial guess for the interest rate, e.g., 5%
  maxIterations: number = 1000,
  tolerance: number = 0.0001
): number {
  let interestRate = initialGuess;
  let iteration = 0;
  let difference: number;

  do {
    const { payment } = calculatePaymentAndDiscount(
      totalFinanceAmount,
      interestRate, // Use current guess for both buy and sell rates
      interestRate,
      termLength,
      paymentType,
      termSegments
    );

    if (isNaN(payment)) {
      console.error("Calculated payment is NaN", {
        interestRate,
        totalFinanceAmount,
        termLength,
        paymentType,
        termSegments,
      });
      // return NaN; // Early exit if payment calculation fails
      // Return zero instead to allow force setting
      return 0;
    }

    difference = payment - basePayment;

    // Calculate derivative (rate of change) of payment with respect to interest rate
    const derivative = calculatePaymentDerivative(
      interestRate,
      totalFinanceAmount,
      termLength,
      paymentType,
      termSegments
    );

    if (isNaN(derivative) || derivative === 0) {
      console.error("Derivative is NaN or zero", { interestRate, derivative });
      // return NaN; // Early exit if derivative calculation fails or is zero
      // Return zero instead to allow force setting
      return 0;
    }

    // Newton-Raphson adjustment
    interestRate -= difference / derivative;

    if (isNaN(interestRate)) {
      console.error("New interest rate is NaN", { difference, derivative });
      // return NaN; // Early exit if new interest rate calculation fails
      // Return zero instead to allow force setting
      return 0;
    }

    iteration++;
  } while (Math.abs(difference) > tolerance && iteration < maxIterations);

  const roundedInterestRate = roundedDollarAmount(interestRate);

  return roundedInterestRate;
}

function calculatePaymentDerivative(
  interestRate: number,
  totalFinanceAmount: number,
  termLength: number,
  paymentType: string,
  termSegments: FinancingOptionSegment[],
  delta: number = 0.01 // A small change in interest rate for derivative calculation
): number {
  // Calculate payment at the current interest rate
  const paymentAtCurrentRate = calculatePaymentAndDiscount(
    totalFinanceAmount,
    interestRate,
    interestRate,
    termLength,
    paymentType,
    termSegments
  ).payment;

  // Calculate payment at a slightly higher interest rate
  const paymentAtHigherRate = calculatePaymentAndDiscount(
    totalFinanceAmount,
    interestRate + delta,
    interestRate + delta,
    termLength,
    paymentType,
    termSegments
  ).payment;

  // Numerical derivative: (f(x + delta) - f(x)) / delta
  const derivative = (paymentAtHigherRate - paymentAtCurrentRate) / delta;

  return derivative;
}

export {
  amortizedPayment,
  calculateInterestRate,
  calculatePaymentAndDiscount,
  calculateTotalFinanceAmount,
  computeAmortizationSchedule,
  presentValueInterestFactor,
  roundedDollarAmount,
  periodicInterestRate,
};
