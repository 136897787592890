import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  useParams,
  useLocation,
  useHistory,
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { usePostHog } from "posthog-js/react";

import { Page, Layout, LegacyStack, Button, Spinner } from "@shopify/polaris";

import queryString from "query-string";

import { useAuth0 } from "@auth0/auth0-react";

import {
  useGetOpportunityQuery,
  useGetVendorProgramQuery,
  useGetVendorPaymentsQuery,
  useGetFinancingOptionsQuery,
  useGetDocusignEnvelopesQuery,
  useGetOpportunityAttachmentsQuery,
  useGetOpportunityEnvelopeTemplatesQuery,
  useGetClientAccountQuery,
  useUpdateDocusignEnvelopeMutation,
  useCreateOpportunityEventMutation,
  useCreateOpportunityPaymentAuthorizationMutation,
} from "../../../../services/api";

import LoadingScreen from "../../../../components/Auth/Loading";
import FourZeroFour from "../../../../components/404";
import ShareHeader from "./components/ShareHeader";
import { SelectTerms } from "./components/SelectTerms";
import PaymentSchedule from "./components/PaymentSchedule";
import ConfirmSigner from "./components/ConfirmSigner";
import UploadAttachments from "./components/UploadAttachments";
import SignDocuments from "./components/SignDocuments";
import Finish from "./components/Finish";

import { CurrentContactContext } from "../../../../contexts/Contact";
import RequestAccess from "../../../Opportunity/RequestAccess";

const FinancingOptionsShare = () => {
  const params = useParams();
  const { opportunityId, pageStepId } = useParams();
  const { currentContact, isLoadingPermissions } = useContext(
    CurrentContactContext
  );
  // const { loginWithRedirect } = useAuth0();

  const posthog = usePostHog();
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const query = queryString.parse(search);
  const [hasDollarOutOptions, setHasDollarOutOptions] = useState(false);
  const [hasStandardOptions, setHasStandardOptions] = useState(false);

  const [nextOrBackButtonPressed, setNextOrBackButtonPressed] = useState(false);

  const [updateDocusignEnvelope, { isLoading: isUpdatingDocusignEnvelope }] =
    useUpdateDocusignEnvelopeMutation();

  useEffect(() => {
    return () => posthog?.reset();
    // Dependencies are intentionally omitted, this effect should only run once
    // on mount and run the cleanup function on unmount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: opportunity = { attributes: {} },
    isLoading: isLoadingOpportunity,
    error: opportunityError,
  } = useGetOpportunityQuery(opportunityId, { skipCache: true });

  const [isPublic, setIsPublic] = useState(false);

  useEffect(() => {
    if (opportunity.attributes.public) {
      setIsPublic(opportunity.attributes.public);
    }
  }, [opportunity]);

  useEffect(() => {
    if (!isLoadingOpportunity) {
      posthog?.group("opportunity", opportunityId, opportunity.attributes);
    }
  }, [isLoadingOpportunity, opportunityId, opportunity, posthog]);

  const {
    data: vendorProgram = { attributes: {} },
    isLoading: isLoadingVendorProgram,
  } = useGetVendorProgramQuery(
    { id: opportunity.attributes.primary_vendor_program_id },
    {
      skip:
        !opportunity.attributes.primary_vendor_program_id || opportunityError,
    }
  );

  const { data: financingOptions = [], isLoading: isLoadingFinancingOptions } =
    useGetFinancingOptionsQuery(opportunityId, {
      skip: !opportunityId || opportunityError,
    });

  const {
    data: opportunityAttachments = [],
    isLoading: isLoadingOpportunityAttachments,
  } = useGetOpportunityAttachmentsQuery(
    { opportunityId: opportunityId, isPublic: true },
    {
      skip: !opportunityId || opportunityError,
    }
  );

  const {
    data: opportunityEnvelopeTemplates = { length: 0 },
    isLoading: isLoadingOpportunityEnvelopeTemplates,
  } = useGetOpportunityEnvelopeTemplatesQuery(
    {
      vendorProgramId: opportunity.attributes.primary_vendor_program_id,
      opportunityId: opportunityId,
    },
    {
      skip:
        !opportunity ||
        !opportunity.attributes.primary_vendor_program_id ||
        opportunityError,
      skipCache: true,
    }
  );

  const {
    data: docusignEnvelopes = [],
    isLoading: isLoadingDocusignEnvelopes,
  } = useGetDocusignEnvelopesQuery(opportunityId, {
    skip: !opportunityId || opportunityError,
  });

  const { data: vendorPayments = [], isLoading: isLoadingVendorPayments } =
    useGetVendorPaymentsQuery(
      {
        opportunityId: opportunityId,
        finalInvoice: opportunity?.attributes?.final_invoice_ids?.length > 0,
      },
      { skip: !opportunity || opportunityError }
    );

  // const handleLogin = () => {
  //   const screen_hint = "signup";

  //   loginWithRedirect({
  //     appState: {
  //       returnTo: window?.location?.pathname + "?step=confirm-signer",
  //     },
  //     authorizationParams: {
  //       screen_hint,
  //     },
  //   });
  // };

  const [selectedFinancingOption, setSelectedFinancingOption] = useState();
  useEffect(() => {
    if (financingOptions.length) {
      const selectedOption = financingOptions.find(
        (financingOption) => financingOption.attributes.selected
      );
      setSelectedFinancingOption(selectedOption);

      setHasStandardOptions(
        financingOptions.find(
          (financingOption) =>
            financingOption.attributes.financing_type == "Standard financing"
        )
          ? true
          : false
      );
      setHasDollarOutOptions(
        financingOptions.find(
          (financingOption) =>
            financingOption.attributes.financing_type == "Dollar out lease"
        )
          ? true
          : false
      );
    }
  }, [financingOptions]);

  const [createOpportunityEvent, { isLoading: isCreatingOpportunityEvent }] =
    useCreateOpportunityEventMutation();

  const handleCreateOpportunityEvent = useCallback(
    (type) => {
      return createOpportunityEvent({
        opportunityId: opportunityId,
        type: type,
        contact_id: currentContact?.id || null,
      })
        .unwrap()
        .then()
        .catch((error) => {
          console.log(error);
        });
    },
    [createOpportunityEvent, opportunityId, currentContact?.id]
  );

  /**
   * Handles the view opportunity event.
   *
   * This function is responsible for handling the event when a user views an opportunity.
   * It checks if the opportunity has been previously viewed and if the notification threshold has been exceeded.
   * If the opportunity has not been viewed or the threshold has been exceeded, it triggers the "OpportunityViewed" event
   * and updates the timestamp for the current opportunity ID in the views object.
   * The updated views object is then saved back to local storage.
   */
  const handleViewOpportunity = useCallback(() => {
    const notificationThreshold = 1000 * 60 * 60; // 60 minutes, adjust as needed
    // Retrieve the views object from localStorage, or initialize an empty object if not set
    const viewsString = localStorage.getItem("fortify-app-opportunity-views");
    const views = viewsString ? JSON.parse(viewsString) : {};
    const lastViewed = views[opportunityId];

    const now = Date.now();
    if (!lastViewed || now - lastViewed > notificationThreshold) {
      handleCreateOpportunityEvent("OpportunityViewed");

      // Update the timestamp for the current opportunity ID
      views[opportunityId] = now;

      // Save the updated views object back to localStorage
      localStorage.setItem(
        "fortify-app-opportunity-views",
        JSON.stringify(views)
      );
    }
  }, [handleCreateOpportunityEvent, opportunityId]);

  useEffect(() => {
    if (
      showLoadingSpinner ||
      isLoadingPermissions ||
      isLoadingVendorProgram ||
      isLoadingOpportunity
    ) {
      return;
    }

    if (
      (opportunity && !isLoadingOpportunity && opportunityError) ||
      (!isPublic && !currentContact)
    ) {
      return;
    } else {
      handleViewOpportunity();
    }
  }, [
    showLoadingSpinner,
    isLoadingPermissions,
    isLoadingVendorProgram,
    isLoadingOpportunity,
    opportunity,
    opportunityError,
    isPublic,
    currentContact,
    handleCreateOpportunityEvent,
    handleViewOpportunity,
  ]);

  const [canClickNext, setCanClickNext] = useState(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(true);
  const [showNextStepSpinner, setShowNextStepSpinner] = useState(false);

  const [steps, setSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState();

  const selectTermsStep = useCallback(() => {
    return {
      stepId: "select-terms",
      pageStepId: "purchase_options",
      pageTitle: "Select Terms",
      content: (
        <SelectTerms
          opportunityId={opportunityId}
          hasStandardOptions={hasStandardOptions}
          hasDollarOutOptions={hasDollarOutOptions}
          hasFinalInvoice={
            opportunity?.attributes?.final_invoice_ids?.length > 0
          }
          setCanClickNext={setCanClickNext}
          setShowNextStepSpinner={setShowNextStepSpinner}
        />
      ),
    };
  }, [
    opportunity?.attributes?.final_invoice_ids,
    opportunityId,
    hasStandardOptions,
    hasDollarOutOptions,
  ]);

  const paymentScheduleStep = useCallback(() => {
    return {
      stepId: "payment-schedule",
      pageStepId: "payment_authorization",
      pageTitle: "Payment Schedule",
      content: (
        <PaymentSchedule
          opportunityId={opportunityId}
          setCanClickNext={setCanClickNext}
        />
      ),
    };
  }, [opportunityId]);

  const confirmSignerStep = useCallback(() => {
    return {
      stepId: "confirm-signer",
      pageStepId: "credit_application",
      pageTitle: "Confirm Signer",
      content: (
        <ConfirmSigner
          isNetTerms={
            selectedFinancingOption?.attributes?.financing_type == "Net terms"
          }
          opportunityId={opportunityId}
          clientId={opportunity?.attributes?.client_id}
          opportunity={opportunity}
          setCanClickNext={setCanClickNext}
        />
      ),
    };
  }, [
    opportunity,
    opportunityId,
    selectedFinancingOption?.attributes?.financing_type,
  ]);

  const uploadAttachmentsStep = useCallback(() => {
    return {
      stepId: "upload-attachments",
      pageStepId: "info_request",
      pageTitle: "Info Request",
      content: (
        <UploadAttachments
          opportunityId={opportunityId}
          setCanClickNext={setCanClickNext}
        />
      ),
    };
  }, [opportunityId]);

  const signDocumentsStep = useCallback(() => {
    return {
      stepId: "sign-documents",
      pageStepId: "document_envelopes",
      pageTitle: "Sign Documents",
      content: (
        <SignDocuments
          opportunityId={opportunityId}
          setCanClickNext={setCanClickNext}
        />
      ),
    };
  }, [opportunityId]);

  const finishStep = useCallback(() => {
    return {
      stepId: "finish",
      pageStepId: "complete",
      pageTitle: "Complete",
      content: <Finish />,
    };
  }, []);

  const pushStep = (stepList, step, order) => {
    stepList.push({
      ...step,
      order: order,
    });
  };

  const buildSteps = useCallback(() => {
    let order = 0;
    let stepList = [];

    // Check for Terms Step
    if (financingOptions.length) {
      pushStep(stepList, selectTermsStep(), order++);
    }

    // Check for Confirm Signer
    pushStep(stepList, confirmSignerStep(), order++);

    // Check for Sign Documents
    if (opportunityEnvelopeTemplates.length || docusignEnvelopes.length) {
      pushStep(stepList, signDocumentsStep(), order++);
    }

    // Check for Upload Attachments
    if (opportunityAttachments.length) {
      pushStep(stepList, uploadAttachmentsStep(), order++);
    }

    // Push Payment Schedule Step
    if (selectedFinancingOption?.attributes?.payments_enabled) { 
      if (selectedFinancingOption?.attributes?.financing_type === "Net terms") {
        if (vendorProgram?.attributes?.stripe_payments) {
          pushStep(stepList, paymentScheduleStep(), order++);
        }
      } else {
        pushStep(stepList, paymentScheduleStep(), order++);
      }
    }

    // Always push Finish Step
    stepList.push({
      ...finishStep(),
      order: order,
    });

    setSteps(stepList);

    // Set current step based on query param
    if (query?.step) {
      const signingStep = stepList.find((step) => step.stepId == query.step);
      const signingStepIndex = stepList.indexOf(signingStep);

      if (signingStepIndex > -1) {
        setCurrentStep(stepList[signingStepIndex]);
      } else {
        setCurrentStep(stepList[0]);
      }
      // Remove query param from URL
      window.history.replaceState(
        {
          ...window.history.state,
          as: location?.pathname,
          url: location?.pathname,
        },
        "",
        location?.pathname
      );
    } else {
      setCurrentStep(stepList[0]);
    }

    setShowLoadingSpinner(false);
  }, [
    confirmSignerStep,
    docusignEnvelopes.length,
    financingOptions.length,
    finishStep,
    location?.pathname,
    opportunityAttachments.length,
    opportunityEnvelopeTemplates.length,
    paymentScheduleStep,
    query.step,
    selectTermsStep,
    selectedFinancingOption?.attributes?.financing_type,
    signDocumentsStep,
    uploadAttachmentsStep,
    vendorProgram?.attributes?.stripe_payments,
  ]);

  // http://localhost:3000/opportunities/f34c2659-3eec-449e-b3d7-fd6d53ff4629/share?envelope_id=085282c5-7819-4911-8797-7643ea37a170&step=sign-documents&event=signing_complete

  useEffect(() => {
    if (
      !opportunityError &&
      !isLoadingFinancingOptions &&
      financingOptions &&
      !isLoadingOpportunityAttachments &&
      !isLoadingOpportunityEnvelopeTemplates &&
      opportunityEnvelopeTemplates &&
      !isLoadingDocusignEnvelopes &&
      !isLoadingVendorProgram
    ) {
      buildSteps();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingFinancingOptions,
    isLoadingOpportunityAttachments,
    isLoadingOpportunityEnvelopeTemplates,
    isLoadingDocusignEnvelopes,
    financingOptions,
    opportunityEnvelopeTemplates,
    opportunityError,
    isLoadingVendorProgram,
    selectedFinancingOption,
  ]);

  const JumpToUploadAttachments = () => {
    useEffect(() => {
      if (!nextOrBackButtonPressed && pageStepId == "info_request") {
        for (let i = 0; i < steps.length; i++) {
          if (steps[i].stepId == "upload-attachments") {
            setCurrentStep(steps[i]);
            break;
          }
        }
      }
    }, []);

    return;
  };

  const JumpToConfirmSigner = () => {
    useEffect(() => {
      if (!nextOrBackButtonPressed && pageStepId == "credit_application") {
        for (let i = 0; i < steps.length; i++) {
          if (steps[i].stepId == "confirm-signer") {
            setCurrentStep(steps[i]);
            break;
          }
        }
      }
    }, []);

    return;
  };

  const JumpToPaymentSchedule = () => {
    useEffect(() => {
      if (!nextOrBackButtonPressed && pageStepId == "payment_authorization") {
        for (let i = 0; i < steps.length; i++) {
          if (steps[i].stepId == "payment-schedule") {
            setCurrentStep(steps[i]);
            break;
          }
        }
      }
    }, []);

    return;
  };

  const JumpToSignDocuments = () => {
    useEffect(() => {
      if (!nextOrBackButtonPressed && pageStepId == "document_envelopes") {
        for (let i = 0; i < steps.length; i++) {
          if (steps[i].stepId == "sign-documents") {
            setCurrentStep(steps[i]);
            break;
          }
        }
      }
    }, []);

    return;
  };

  const handleBackButton = () => {
    setNextOrBackButtonPressed(true);

    const currentOrder = currentStep.order;

    let stepToMoveTo = {};
    for (let i = 0; i < steps.length; i++) {
      if (steps[i].order == currentOrder - 1) {
        if (
          steps[i].stepId == "payment-schedule" &&
          (!vendorProgram?.attributes?.stripe_payments ||
            !selectedFinancingOption.attributes.approved)
        ) {
          setCurrentStep(steps[i - 1]);
        } else if (steps[i].stepId == "sign-documents") {
          // If Vendor Program requires credit submission, make sure selected financing option is approved
          // If not, skip step
          if (
            vendorProgram?.attributes?.requires_credit_submission &&
            !selectedFinancingOption.attributes.approved
          ) {
            stepToMoveTo = steps[i - 1];
          } else {
            stepToMoveTo = steps[i];
          }
        } else {
          stepToMoveTo = steps[i];
        }
        break;
      }
    }

    if (stepToMoveTo.stepId) {
      window.history.replaceState(
        null,
        stepToMoveTo.pageTitle,
        `/opportunities/${opportunityId}/share/${stepToMoveTo.pageStepId}`
      );
      setCurrentStep(stepToMoveTo);
    }
  };

  const handleNextStepButton = () => {
    // const currentStepId = currentStep.stepId;
    // const isNextToLastStep = currentStep?.order == steps.length - 2;
    // if (currentStepId == "confirm-signer") {
    //   if (
    //     selectedFinancingOption?.attributes?.financing_type != "Net terms" &&
    //     !currentContact &&
    //     !isNextToLastStep
    //   ) {
    //     handleLogin();
    //   } else {
    //     proceedToNextStep();
    //   }
    // } else {
    //   proceedToNextStep();
    // }
    //
    // We are no longer enforcing login on the documents step
    // as such this action does not need the logic above.
    proceedToNextStep();
  };

  const proceedToNextStep = useCallback(() => {
    setNextOrBackButtonPressed(true);
    const currentOrder = currentStep?.order;
    let stepToMoveTo = {};

    // Calculate hasDifferentVendorIds once, outside the loop
    let hasDifferentVendorIds = false;
    for (let i = 1; i < vendorPayments.length; i++) {
      if (
        vendorPayments[i].attributes.vendor_id !=
        vendorPayments[i - 1].attributes.vendor_id
      ) {
        hasDifferentVendorIds = true;
        break;
      }
    }

    if (!selectedFinancingOption?.attributes?.approved) {
      for (let i = 0; i < steps.length; i++) {
        if (steps[i].order >= currentOrder + 1) {
          // Only confirm-signer, upload-attachments, finish steps are allowed
          if (
            steps[i].stepId == "confirm-signer" ||
            steps[i].stepId == "upload-attachments" ||
            steps[i].stepId == "finish"
          ) {
            stepToMoveTo = steps[i];
            setCanClickNext(false);
            break;
          }
        }
      }
    } else {
      for (let i = 0; i < steps.length; i++) {
        if (steps[i].order == currentOrder + 1) {
          if (steps[i].stepId == "payment-schedule") {
            stepToMoveTo = hasDifferentVendorIds ? steps[i + 1] : steps[i];
          } else if (steps[i].stepId == "sign-documents") {
            stepToMoveTo =
              vendorProgram?.attributes?.requires_credit_submission &&
              !selectedFinancingOption.attributes.approved
                ? steps[i + 1]
                : steps[i];
          } else {
            stepToMoveTo =
              hasDifferentVendorIds ||
              !selectedFinancingOption.attributes.approved
                ? steps[i + 1]
                : steps[i];
          }
          setCanClickNext(false);
          break;
        }
      }
    }

    if (stepToMoveTo.stepId) {
      window.history.replaceState(
        null,
        stepToMoveTo.pageTitle,
        `/opportunities/${opportunityId}/share/${stepToMoveTo.pageStepId}`
      );
      setCurrentStep(stepToMoveTo);
    }
  }, [
    currentStep,
    selectedFinancingOption?.attributes?.approved,
    steps,
    vendorPayments,
    vendorProgram?.attributes?.requires_credit_submission,
  ]);

  useEffect(() => {
    const event = query.event;
    const envelopeId = query.envelope_id;
    const remainingQuery = Object.keys(query).reduce((acc, key) => {
      if (key !== "envelope_id") {
        acc[key] = query[key];
      }
      return acc;
    }, {});

    // if there are no steps or currentStep, return
    if (
      !steps.length ||
      !currentStep ||
      isLoadingVendorProgram ||
      isLoadingOpportunity ||
      isLoadingFinancingOptions
    ) {
      return;
    }

    if (event === "signing_complete" && envelopeId) {
      // history.push with preserved parameters
      history.push({
        search: queryString.stringify(remainingQuery),
      });

      updateDocusignEnvelope({
        id: envelopeId,
        opportunityId: opportunityId,
        docusign_status: "signed",
      })
        .unwrap()
        .then(() => {
          // proceedToNextStep();

          return { status: "success" };
        })
        .catch((error) => {
          // Consider handling the error
          console.error("Error updating DocuSign envelope:", error);
        });
    }
  }, [
    query.event,
    query.envelope_id,
    opportunityId,
    updateDocusignEnvelope,
    history,
    steps.length,
    currentStep,
    isLoadingVendorProgram,
    isLoadingOpportunity,
    isLoadingFinancingOptions,
    query,
  ]);

  const pageMarkup = (
    <div style={{ background: "white", height: "100%" }}>
      <Page>
        <Layout>
          {!showLoadingSpinner && (
            <ShareHeader
              // contactId={contactId}
              opportunity={opportunity}
              vendorProgram={vendorProgram}
              progress={
                currentStep ? ((currentStep.order + 1) / steps.length) * 100 : 0
              }
            />
          )}

          <Layout.Section>
            {showLoadingSpinner && (
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "304px",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    left: "calc(50% - 2.5rem)",
                    top: "7.5rem",
                  }}
                >
                  <Spinner size="large" />
                </div>
              </div>
            )}

            {!showLoadingSpinner && (
              <Router>
                <Switch>
                  <Route
                    path={`/opportunities/:opportunityId/share/credit_application`}
                  >
                    <JumpToConfirmSigner />
                  </Route>
                  <Route
                    path={`/opportunities/:opportunityId/share/info_request`}
                  >
                    <JumpToUploadAttachments />
                  </Route>
                  <Route
                    path={`/opportunities/:opportunityId/share/payment_authorization`}
                  >
                    <JumpToPaymentSchedule />
                  </Route>
                  <Route
                    path={`/opportunities/:opportunityId/share/document_envelopes`}
                  >
                    <JumpToSignDocuments />
                  </Route>
                </Switch>
              </Router>
            )}

            {!showLoadingSpinner && currentStep && currentStep?.content}

            <br />

            {!showLoadingSpinner && (
              <div style={{ paddingBottom: "100px" }}>
                <LegacyStack spacing="tight">
                  {currentStep?.order != 0 && (
                    <Button onClick={handleBackButton}>Back</Button>
                  )}

                  {currentStep?.order < steps.length - 2 && (
                    <Button
                      disabled={!canClickNext}
                      onClick={handleNextStepButton}
                      loading={showNextStepSpinner}
                      variant="primary"
                    >
                      Next Step
                    </Button>
                  )}

                  {currentStep?.order == steps.length - 2 && (
                    <Button
                      disabled={!canClickNext}
                      onClick={handleNextStepButton}
                      variant="primary"
                    >
                      Complete
                    </Button>
                  )}
                </LegacyStack>
              </div>
            )}
          </Layout.Section>
        </Layout>
      </Page>
    </div>
  );

  if (opportunityError) {
    if (opportunityError.status == 403) {
      return <RequestAccess opportunityId={opportunityId} />;
    } else {
      return <LoadingScreen />;
    }
  }

  if (
    showLoadingSpinner ||
    isLoadingPermissions ||
    isLoadingVendorProgram ||
    isLoadingOpportunity
  )
    return <LoadingScreen />;

  return (opportunity && !isLoadingOpportunity && opportunityError) ||
    (!isPublic && !currentContact) ? (
    <FourZeroFour />
  ) : (
    pageMarkup
  );
};

export default FinancingOptionsShare;
