import React, { useState, useEffect, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";

import {
  Card,
  Button,
  LegacyStack,
  Icon,
  ResourceItem,
  Truncate,
  ResourceList,
  EmptyState,
  Modal,
  TextField,
  Text,
  Toast,
  FormLayout,
  Tooltip,
  Thumbnail,
  Spinner,
  Box,
  Layout,
  InlineStack,
  Badge,
  Popover,
  ActionList,
  Banner,
  Link,
  BlockStack,
  DataTable,
} from "@shopify/polaris";
import {
  LinkIcon,
  NoteIcon,
  DragHandleIcon,
  XSmallIcon,
  MenuHorizontalIcon,
  ConnectIcon,
} from "@shopify/polaris-icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  useGetOpportunityQuery,
  useGetOpportunityAttachmentsQuery,
  useGetOpportunityCreditSubmissionsQuery,
  useGetOpportunityTransactionsQuery,
  useCreateOpportunityTransactionMutation,
  useUpdateOpportunityTransactionsMutation,
  useValidateStripeCustomerReferenceMutation,
  useDeleteOpportunityTransactionsMutation,
  useGetFinancingOptionsQuery,
  useGetStripeListPaymentMethodsQuery,
  useGetStripeListAllPaymentMethodsQuery,
  useGetStripeSetupIntentQuery,
  useGetVendorPaymentsQuery,
  baseQuery,
  useScheduleOpportunityTransactionsMutation,
} from "../../services/api";

import {
  formatCurrency,
  formatNumericDate,
  convertServerDate,
} from "../../utilities";

import { calculatePaymentAndDiscount } from "../../finance";

import OpportunitiesReviewForm from "./ReviewForm";
import FinalInvoiceList from "../../components/VendorPayment/FinalInvoiceList";
import NewTransactionModal from "./PaymentsAndReview/NewTransactionModal";
import EditTransactionModal from "./PaymentsAndReview/EditTransactionModal";
import CardBoxTitle from "../../components/Card/BoxTitle";
import PaymentMethodResourceItem from "../../components/Stripe/PaymentMethodResourceItem";

import emptySearchCompanies from "../../assets/emptySearchCompanies.svg";
import pluralize from "../../pluralizeConfig";

import { CurrentContactContext } from "../../contexts/Contact";

import TransactionBadge from "../../components/Transaction/TransactionBadge";
import InstallmentPaymentScheduleCard from "./PaymentsAndReview/InstallmentPaymentScheduleCard";
import PaymentMethodCard from "../../components/Opportunity/PaymentMethodCard";

const OpportunitiesPaymentsAndReview = () => {
  const { id: opportunityId, clientId } = useParams();

  const queryStrings = () => {
    return new URLSearchParams(location.search);
  };
  const transactionIdState = queryStrings().get("transaction_id");

  const { isOrganizationAdmin, isVendorAdmin } = useContext(
    CurrentContactContext
  );

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);

  // const [updateOpportunity] = useUpdateOpportunityMutation();

  const {
    data: opportunity,
    isLoading: isLoadingOpportunity,
    error: opportunityError,
  } = useGetOpportunityQuery(opportunityId);

  const [designatedSignerId, setDesignatedSignerId] = useState();
  const [stripeVendorId, setStripeVendorId] = useState(null);

  const {
    data: stripeSetupIntent,
    isLoading: isLoadingStripeSetupIntent,
    error: stripeSetupIntentError,
  } = useGetStripeSetupIntentQuery(
    {
      contactId: designatedSignerId,
      vendorId: stripeVendorId,
      opportunityId: opportunityId,
    },
    {
      skip: !stripeVendorId || !designatedSignerId || !opportunityId,
    }
  );

  useEffect(() => {
    if (opportunity.attributes.designated_signer_id)
      setDesignatedSignerId(opportunity.attributes.designated_signer_id);
  }, [opportunity]);

  const { data: vendorPayments = [], isLoading: isLoadingVendorPayments } =
    useGetVendorPaymentsQuery(
      {
        opportunityId: opportunityId,
        finalInvoice: opportunity?.attributes?.final_invoice_ids?.length > 0,
      },
      { skip: !opportunity }
    );

  useEffect(() => {
    if (vendorPayments.length > 0) {
      let currentStripeVendorId = undefined;

      // First check if there is a final invoice
      for (let i = 0; i < vendorPayments.length; i++) {
        if (
          opportunity?.attributes?.final_invoice_ids?.includes(
            vendorPayments[i].id
          )
        ) {
          currentStripeVendorId = vendorPayments[i].attributes.vendor_id;
          break;
        } else {
          if (vendorPayments[i].attributes.vendor_stripe_connected_account_id) {
            currentStripeVendorId = vendorPayments[i].attributes.vendor_id;
          }
        }
      }

      if (!currentStripeVendorId) {
        // If no stripe ID exists at all, set to zero and let it error
        currentStripeVendorId = vendorPayments[0].attributes.vendor_id;
      }

      setStripeVendorId(currentStripeVendorId);
    }
  }, [opportunity?.attributes?.final_invoice_ids, vendorPayments]);

  const {
    data: paymentMethods = [],
    isLoading: isLoadingPaymentMethods,
    isFetching: isFetchingPaymentMethods,
  } = useGetStripeListPaymentMethodsQuery(
    {
      contactId: designatedSignerId,
      vendorId: stripeVendorId,
    },
    { skip: !designatedSignerId || !stripeVendorId }
  );

  const {
    data: allPaymentMethods = [],
    isLoading: isLoadingAllPaymentMethods,
    isFetching: isFetchingAllPaymentMethods,
  } = useGetStripeListAllPaymentMethodsQuery(
    {
      contactId: designatedSignerId,
    },
    { skip: !designatedSignerId }
  );

  const [
    validateStripeCustomerReference,
    { isLoading: isValidatingStripeCustomerReference },
  ] = useValidateStripeCustomerReferenceMutation();

  const { data: financingOptions = [], isLoading: isLoadingFinancingOptions } =
    useGetFinancingOptionsQuery(opportunityId);

  const [selectedFinancingOption, setSelectedFinancingOption] = useState(null);

  useEffect(() => {
    if (financingOptions) {
      for (let i = 0; i < financingOptions.length; i++) {
        if (
          financingOptions[i].attributes.approved &&
          financingOptions[i].attributes.selected
        ) {
          setSelectedFinancingOption(financingOptions[i]);
          break;
        }
      }
    }
  }, [financingOptions]);

  const { data: creditSubmissions, isLoading: isLoadingCreditSubmissions } =
    useGetOpportunityCreditSubmissionsQuery(opportunityId);

  const [selectedCreditSubmission, setSelectedCreditSubmission] =
    useState(null);

  useEffect(() => {
    if (creditSubmissions) {
      for (let i = 0; i < creditSubmissions.length; i++) {
        if (creditSubmissions[i].attributes.selected_at) {
          setSelectedCreditSubmission(creditSubmissions[i]);
          break;
        }
      }
    }
  }, [creditSubmissions]);

  const {
    data: opportunityAttachments = [],
    isLoading: isLoadingopportunityAttachments = false,
  } = useGetOpportunityAttachmentsQuery(
    { opportunityId: opportunityId, isPublic: false },
    { skip: !opportunityId }
  );

  const {
    data: documents,
    isLoading: isDocumentsLoading = false,
    refetch: refetchDocuments,
  } = useGetOpportunityAttachmentsQuery(
    { opportunityId: opportunityId, isPublic: false },
    { skip: !opportunityId }
  );

  const {
    data: transactions = [],
    isLoading: isLoadingTransactions = false,
    refetch: refetchTransactions,
  } = useGetOpportunityTransactionsQuery(opportunityId, {
    skip: !opportunityId,
  });

  const [createTransaction, { isLoading: isCreatingTransaction }] =
    useCreateOpportunityTransactionMutation();

  const [updateTransactions, { isLoading: isUpdatingTransactions }] =
    useUpdateOpportunityTransactionsMutation();

  const [deleteTransactions, { isLoading: isDeletingTransactions }] =
    useDeleteOpportunityTransactionsMutation();

  useEffect(() => {
    if (transactionIdState && transactions.length > 0) {
      for (let i = 0; i < transactions.length; i++) {
        if (
          transactions[i].attributes.installment_payment_id ==
            transactionIdState ||
          transactions[i].id == transactionIdState
        ) {
          setCurrentTransactionId(transactions[i].id);
          setCurrentTransaction(transactions[i]);
          toggleEditTransactionModal();
          break;
        }
      }
    }
  }, [transactionIdState, transactions]);

  const [computeTransactions, setComputeTransactions] = useState({
    base_payments_count: 0,
    base_payments_value: 0,
    origination_fee_customer: 0,
    origination_fee_lender: 0,
    doc_fee_paid: 0,
    debt_present_value: 0,
    transaction_expense: 0,
    doc_fee_lender: 0,
    other_fee_lender: 0,
    total_fees: 0,
    progress_payment_interim_rent: 0,
    progress_payment_interest_cost: 0,
    vendor_points: 0,
    subsidy: 0,
    associate_referral_fee: 0,
    sum_of_remaining_payments: 0,
    vendor_admin_payables: 0,
    net_debt_pv: 0,
    debt_pv_base_payment: 0,
    days_between_invoice_date_and_created_date: "--",
    seconds_from_created_at_to_paid_date: "--",
    days_between_invoice_due_date_and_invoice_paid: "--",
  });

  const computeCount = function (transactions, type, detail) {
    let count = 0;
    for (let i = 0; i < transactions.length; i++) {
      if (
        transactions[i].attributes.transaction_type === type &&
        transactions[i].attributes.detail === detail
      ) {
        count++;
      }
    }

    return count;
  };

  const computeValue = function (transactions, type, detail, status = "any") {
    let value = 0;
    for (let i = 0; i < transactions.length; i++) {
      if (
        transactions[i].attributes.transaction_type === type &&
        transactions[i].attributes.detail
          ?.toLowerCase()
          .indexOf(detail.toLowerCase()) >= 0
      ) {
        if (status === "any" || transactions[i].attributes.status == status) {
          value = value + transactions[i].attributes.amount;
        }
      }
    }

    return value;
  };

  const computeOriginationFeeFromLender = useCallback((transactions) => {
    return computeValue(
      transactions,
      "receivable",
      "Fee - Origination Fee from Lender"
    );
  }, []);

  const computeDocFeeToLender = useCallback((transactions) => {
    return computeValue(transactions, "payable", "Expense - Lender doc fee");
  }, []);

  const computeOtherFeeToLender = useCallback((transactions) => {
    return computeValue(transactions, "payable", "Fee - Other fee to Lender");
  }, []);

  const computeProgressPaymentInterimRent = useCallback((transactions) => {
    return computeValue(
      transactions,
      "receivable",
      "Customer Charge - Progress Payment"
    );
  }, []);

  const computeProgressPaymentInterestCost = useCallback((transactions) => {
    return computeValue(
      transactions,
      "payable",
      "Expense - Progress Payment Interest Cost"
    );
  }, []);

  const computeVendorPoints = useCallback((transactions) => {
    return computeValue(transactions, "payable", "Fee - Vendor Referral");
  }, []);

  const computeAssociateReferralFee = useCallback((transactions) => {
    return computeValue(transactions, "payable", "Fee - Associate Referral");
  }, []);

  const computeSumOfAllVendorPayments = useCallback((transactions) => {
    let allVendorPayments = computeValue(
      transactions,
      "payable",
      "Vendor Payout",
      "completed"
    );

    return allVendorPayments;
  }, []);

  const computeSubsidy = useCallback(
    (transactions) => {
      let totalPaidToVendor = computeSumOfAllVendorPayments(transactions);
      let totalInvoiceAmount = opportunity.attributes.total_finance_amount;

      return totalInvoiceAmount - totalPaidToVendor;
    },
    [opportunity]
  );

  const computeVendorAdminPayables = useCallback((transactions) => {
    let vendorInstallment = computeValue(
      transactions,
      "payable",
      "Vendor Payout - Net terms installment payment"
    );
    let vendorDownpayment = computeValue(
      transactions,
      "payable",
      "Vendor Payout - Down Payment"
    );

    return vendorInstallment + vendorDownpayment;
  }, []);

  const computeDebtPVBasePayment = useCallback(() => {
    if (!selectedFinancingOption || !selectedCreditSubmission) return 0;

    let totalFinanceAmount =
      selectedFinancingOption.attributes.total_finance_amount;
    let lenderBuyRate = selectedCreditSubmission.attributes.buy_rate;
    let sellRate = selectedFinancingOption.attributes.customer_interest_rate;
    let termLength = selectedFinancingOption.attributes.term_length;
    let paymentType = selectedFinancingOption.attributes.payment_type;

    let { schedule, sellPayment, discount, buyPresentValue } =
      calculatePaymentAndDiscount(
        totalFinanceAmount,
        lenderBuyRate,
        //sellRate,
        lenderBuyRate,
        parseInt(termLength),
        paymentType,
        []
      );

    return sellPayment;
  }, [selectedCreditSubmission, selectedFinancingOption]);

  useEffect(() => {
    if (transactions.length > 0 && opportunity) {
      // let basePayment = computeBasePaymentsCount(transactions);
      let docFeeToLender = computeDocFeeToLender(transactions);
      let otherFeeToLender = computeOtherFeeToLender(transactions);
      // let debt_pv = computeDebtPresentValue(transactions);
      const debt_pv = opportunity.attributes?.debt_present_value;
      const total_fees = opportunity.attributes?.total_fees;
      let computations = {
        base_payments_count: opportunity.attributes?.total_base_payments_count,
        base_payments_value: opportunity.attributes?.total_base_payments_amount,
        origination_fee_customer:
          opportunity?.attributes?.origination_fee_from_customer,
        //computeOriginationFeeFromCustomer(transactions),
        origination_fee_lender: computeOriginationFeeFromLender(transactions),
        // doc_fee_paid: computeDocFee(transactions),
        doc_fee_paid: opportunity?.attributes?.documentation_fee_paid,
        debt_present_value: debt_pv,
        // transaction_expense: computeTransactionExpense(transactions),
        transaction_expense: opportunity.attributes?.total_transaction_expense,
        doc_fee_lender: docFeeToLender,
        other_fee_lender: otherFeeToLender,
        // total_fees: computeTotalFees(transactions),
        total_fees: opportunity.attributes?.total_fees,
        progress_payment_interim_rent:
          computeProgressPaymentInterimRent(transactions),
        progress_payment_interest_cost:
          computeProgressPaymentInterestCost(transactions),
        vendor_points: computeVendorPoints(transactions),
        subsidy: opportunity.attributes?.subsidy,
        associate_referral_fee: computeAssociateReferralFee(transactions),
        // sum_of_remaining_payments: computeSumOfRemainingPayments(transactions),
        sum_of_remaining_payments:
          opportunity.attributes?.sum_of_remaining_payments,
        vendor_admin_payables: computeVendorAdminPayables(transactions),
        net_debt_pv: debt_pv - total_fees,
        debt_pv_base_payment: computeDebtPVBasePayment(),
        total_paid_to_vendor: computeSumOfAllVendorPayments(transactions),
        days_between_invoice_date_and_created_date:
          opportunity.attributes?.days_between_invoice_date_and_created_date,
        seconds_from_created_at_to_paid_date:
          opportunity.attributes?.seconds_from_created_at_to_paid_date,
        days_between_invoice_due_date_and_invoice_paid:
          opportunity.attributes
            ?.days_between_invoice_due_date_and_invoice_paid,
      };

      setComputeTransactions(computations);
    }
  }, [
    computeAssociateReferralFee,
    computeDocFeeToLender,
    computeOriginationFeeFromLender,
    computeOtherFeeToLender,
    computeProgressPaymentInterestCost,
    computeProgressPaymentInterimRent,
    computeSubsidy,
    computeVendorPoints,
    transactions,
    opportunity,
    isLoadingOpportunity,
    selectedFinancingOption,
    computeVendorAdminPayables,
    computeDebtPVBasePayment,
    computeSumOfAllVendorPayments,
  ]);

  const attachmentResourceName = {
    singular: "attachment",
    plural: "attachments",
  };

  const [selectedAttachments, setSelectedAttachments] = useState([]);

  const attachmentsResourceList = (item) => {
    const { name } = item;

    return (
      <ResourceItem
        id={name}
        onClick={() => {}}
        accessibilityLabel={`View details for credit submission name`}
        verticalAlignment="center"
      >
        <LegacyStack>
          <div>
            <div className="credit-submissions-icon">
              <Icon source={NoteIcon} tone="subdued" />
            </div>
          </div>

          <div>
            <div>
              <h3>
                <Text as="span" fontWeight="semibold">
                  <Truncate>{name}</Truncate>
                </Text>
              </h3>
            </div>
          </div>
        </LegacyStack>
      </ResourceItem>
    );
  };

  const emptyAttachmentsState = (
    <EmptyState
      heading="This is where you'll manage your attachments"
      image={emptySearchCompanies}
    >
      <p>There are no attachments.</p>
    </EmptyState>
  );

  const attachmentBulkActions = [
    {
      content: "Download selected docs",
      onAction: () => console.log("Download selected docs"),
    },
  ];

  const attachmentsResourceListLayout = (
    <ResourceList
      resourceName={attachmentResourceName}
      items={opportunityAttachments}
      renderItem={(item) => attachmentsResourceList(item.attributes)}
      selectedItems={selectedAttachments}
      onSelectionChange={setSelectedAttachments}
      emptyState={emptyAttachmentsState}
      loading={isLoadingopportunityAttachments}
      showHeader
      selectable
      promotedBulkActions={attachmentBulkActions}
    />
  );
  const [transactionSelectedItems, setTransactionSelectedItems] = useState([]);

  const [showNewTransactionModal, setShowNewTransactionModal] = useState(false);

  const toggleNewTransactionModal = () => {
    setShowNewTransactionModal(!showNewTransactionModal);
  };

  const hasCompletedTransactionsSelected = transactionSelectedItems.some(
    (transactionId) => {
      const transaction = transactions.find(
        (transaction) => transaction.id === transactionId
      );
      return transaction.attributes.status === "completed";
    }
  );

  const hasCompletedTransactionsWithStripePaymentsSelected =
    transactionSelectedItems.some((transactionId) => {
      const transaction = transactions.find(
        (transaction) => transaction.id === transactionId
      );
      return (
        transaction.attributes.status === "completed" &&
        transaction.attributes.stripe_payment_intent_id
      );
    });

  const hasVendorPayoutsSelected = transactionSelectedItems.every(
    (transactionId) => {
      const transaction = transactions.find(
        (transaction) => transaction.id === transactionId
      );
      return transaction.attributes.detail
        .toLowerCase()
        .includes("vendor payout");
    }
  );

  const hasCustomerChargeSelected = transactionSelectedItems.every(
    (transactionId) => {
      const transaction = transactions.find(
        (transaction) => transaction.id === transactionId
      );
      return transaction.attributes.detail
        .toLowerCase()
        .includes("customer charge");
    }
  );

  const canConvertToLenderPayments = transactionSelectedItems.every(
    (transactionId) => {
      const transaction = transactions.find(
        (transaction) => transaction.id === transactionId
      );
      return (
        transaction.attributes.detail.toLowerCase().includes("vendor payout") ||
        transaction.attributes.detail.toLowerCase().includes("customer charge")
      );
    }
  );

  const bulkDeleteTransactions = useCallback(() => {
    return deleteTransactions({
      opportunityId: opportunityId,
      body: {
        transaction_ids: transactionSelectedItems,
      },
    })
      .unwrap()
      .then(() => {
        setTransactionSelectedItems([]);
        setToastMessage(
          `${pluralize(
            "Transaction",
            transactionSelectedItems.length
          )} deleted.`
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [deleteTransactions, opportunityId, transactionSelectedItems]);

  const handleUpdateTransactions = (form) => {
    return updateTransactions({
      ...form,
      opportunityId: opportunityId,
      transactionIds: transactionSelectedItems,
    })
      .then(() => {
        setTransactionSelectedItems([]);
        setToastMessage(
          `${pluralize(
            "Transaction",
            transactionSelectedItems.length
          )} updated.`
        );
        setShowToast(true);
      })
      .catch((error) => console.log(error));
  };

  const handleChangeToVendorPayments = useCallback(() => {
    changeTransactionDetails("vendor payout");
  }, [changeTransactionDetails, transactionSelectedItems]);

  const handleChangeToLenderPayments = useCallback(() => {
    changeTransactionDetails("lender payment");
  }, [changeTransactionDetails, transactionSelectedItems]);

  const handleChangeToCustomerCharges = useCallback(() => {
    changeTransactionDetails("customer charge");
  }, [changeTransactionDetails, transactionSelectedItems]);

  const changeTransactionDetails = useCallback(
    (detailToConvert) => {
      return updateTransactions({
        opportunityId: opportunityId,
        transactionIds: transactionSelectedItems,
        detail_conversion: detailToConvert,
      })
        .then(() => {
          refetchTransactions();
          setTransactionSelectedItems([]);
          setToastMessage(`Valid transactions changed to ${detailToConvert}`);
          setShowToast(true);
        })
        .catch((error) => console.log(error));
    },
    [
      opportunityId,
      transactionSelectedItems,
      updateTransactions,
      refetchTransactions,
    ]
  );

  const [completedAtDate, setCompletedAtDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  const handleMarkTransactionsCompleted = () => {
    return handleUpdateTransactions({ completed_at: completedAtDate }).then(
      () => {
        setShowMarkCompleteModal(false);
      }
    );
  };

  const [transactionPromotedBulkActions, setTransactionPromotedBulkActions] =
    useState([
      {
        content: `Delete ${pluralize(
          "transaction",
          transactionSelectedItems.length
        )}`,
        onAction: bulkDeleteTransactions,
        disabled:
          (hasCompletedTransactionsSelected && !isOrganizationAdmin) ||
          hasCompletedTransactionsWithStripePaymentsSelected,
      },
    ]);

  useEffect(() => {
    setTransactionPromotedBulkActions([
      {
        content: `Delete ${pluralize(
          "transaction",
          transactionSelectedItems.length
        )}`,
        onAction: bulkDeleteTransactions,
        disabled:
          (hasCompletedTransactionsSelected && !isOrganizationAdmin) ||
          hasCompletedTransactionsWithStripePaymentsSelected,
      },
      {
        content: "Change to vendor payouts",
        loading: refetchTransactions || isLoadingTransactions,
        onAction: handleChangeToVendorPayments,
        disabled:
          !hasCustomerChargeSelected ||
          hasCompletedTransactionsSelected ||
          !isOrganizationAdmin,
      },
      {
        content: "Change to lender payments",
        loading: refetchTransactions || isLoadingTransactions,
        onAction: handleChangeToLenderPayments,
        disabled:
          !canConvertToLenderPayments ||
          hasCompletedTransactionsSelected ||
          !isOrganizationAdmin,
      },
      {
        content: "Change to customer charges",
        loading: refetchTransactions || isLoadingTransactions,
        onAction: handleChangeToCustomerCharges,
        disabled:
          !hasVendorPayoutsSelected ||
          hasCompletedTransactionsSelected ||
          !isOrganizationAdmin,
      },
    ]);
  }, [
    bulkDeleteTransactions,
    hasCompletedTransactionsSelected,
    hasVendorPayoutsSelected,
    hasCustomerChargeSelected,
    canConvertToLenderPayments,
    isOrganizationAdmin,
    transactionSelectedItems,
    refetchTransactions,
    isLoadingTransactions,
    handleChangeToVendorPayments,
    handleChangeToLenderPayments,
    handleChangeToCustomerCharges,
    hasCompletedTransactionsWithStripePaymentsSelected,
  ]);

  const transactionResourceName = {
    singular: "transaction",
    plural: "transactions",
  };

  const transactionResourceListItem = (transaction) => {
    const transactionId = transaction.id;
    const {
      transaction_type,
      detail,
      amount,
      status,
      completed_at,
      created_at,
      scheduled_date,
      payout_destination,
      limited_detail,
    } = transaction.attributes;

    return (
      <ResourceItem
        id={transactionId}
        onClick={() => {
          setCurrentTransactionId(transactionId);
          setCurrentTransaction(transaction);
          toggleEditTransactionModal();
        }}
        verticalAlignment="center"
      >
        <BlockStack alignment="center" wrap="false">
          <InlineStack
            gap={"400"}
            wrap="false"
            blockAlign={"center"}
            align={"start"}
          >
            <div style={{ maxWidth: "64px", minWidth: "64px" }}>
              <Tooltip
                content={`Transaction ${
                  scheduled_date ? "scheduled for" : "created"
                } ${formatNumericDate(
                  new Date(
                    scheduled_date ? scheduled_date?.split("-") : created_at
                  )
                )} ${scheduled_date ? "" : "and is pending scheduling."}`}
              >
                <Text as="span" color={scheduled_date ? "" : "subdued"}>
                  {scheduled_date
                    ? `${formatNumericDate(
                        new Date(scheduled_date?.split("-"))
                      )}`
                    : "--/--/--"}
                </Text>
              </Tooltip>
            </div>

            <div style={{ maxWidth: "64px", minWidth: "64px" }}>
              <Tooltip
                content={`Transaction was ${
                  completed_at ? "completed" : "created"
                } ${formatNumericDate(
                  new Date(completed_at ? completed_at?.split("-") : created_at)
                )} ${completed_at ? "" : "and is pending completion."}`}
              >
                <Text as="span" color={completed_at ? "" : "subdued"}>
                  {completed_at
                    ? `${formatNumericDate(new Date(completed_at?.split("-")))}`
                    : "--/--/--"}
                </Text>
              </Tooltip>
            </div>

            <div style={{ maxWidth: "22rem" }}>
              <h3>
                <Text as="span" fontWeight="semibold">
                  <Truncate>
                    {isOrganizationAdmin ? detail : limited_detail}
                  </Truncate>
                </Text>
              </h3>
            </div>

            {!isOrganizationAdmin && detail.includes("Lender Payment") ? (
              <></>
            ) : (
              <TransactionBadge status={status} />
            )}

            <div style={{ textAlign: "right", flex: "1" }}>
              <Text
                as="span"
                color={transaction_type == "payable" ? "subdued" : ""}
              >
                {isVendorAdmin && transaction_type == "payable"
                  ? ""
                  : transaction_type == "payable"
                  ? "-"
                  : ""}
                {formatCurrency(amount)}
              </Text>
            </div>
          </InlineStack>

          {payout_destination && (
            <InlineStack align={"start"}>
              <Text variant="bodySm" as="p">
                Destination: {payout_destination}
              </Text>
            </InlineStack>
          )}
        </BlockStack>
      </ResourceItem>
    );
  };

  const [showMarkCompleteModal, setShowMarkCompleteModal] = useState(false);
  const markCompleteModal = showMarkCompleteModal && (
    <Modal
      open={showMarkCompleteModal}
      onClose={() => setShowMarkCompleteModal(false)}
      title={`Mark ${pluralize(
        "transaction",
        transactionSelectedItems.length
      )} complete`}
      primaryAction={{
        content: "Save",
        onAction: handleMarkTransactionsCompleted,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => setShowMarkCompleteModal(false),
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <TextField
            label={"Completed date"}
            type="date"
            value={completedAtDate}
            onChange={setCompletedAtDate}
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  const newTransactionModal = (
    <NewTransactionModal
      opportunityId={opportunityId}
      showNewTransactionModal={showNewTransactionModal}
      toggleNewTransactionModal={toggleNewTransactionModal}
      setToastMessage={setToastMessage}
      setShowToast={setShowToast}
      isVendorAdmin={isVendorAdmin}
      isOrganizationAdmin={isOrganizationAdmin}
    />
  );

  const [showEditTransactionModal, setShowEditTransactionModal] =
    useState(false);
  const [currentTransactionId, setCurrentTransactionId] = useState("");
  const [currentTransaction, setCurrentTransaction] = useState(null);
  const toggleEditTransactionModal = () => {
    setShowEditTransactionModal(!showEditTransactionModal);
  };

  const editTransactionModal = (
    <EditTransactionModal
      opportunityId={opportunityId}
      transactionId={currentTransactionId}
      transaction={currentTransaction}
      showEditTransactionModal={showEditTransactionModal}
      toggleEditTransactionModal={toggleEditTransactionModal}
      setToastMessage={setToastMessage}
      setShowToast={setShowToast}
      isVendorAdmin={isVendorAdmin}
      isOrganizationAdmin={isOrganizationAdmin}
    />
  );

  const transactionResourceListAlternateTool = (
    <InlineStack gap="400">
      <Button
        onClick={() => {
          handleScheduleTransactions();
        }}
        disabled={!opportunity?.attributes?.installment_payment_start_date}
      >
        Schedule transactions
      </Button>

      {isOrganizationAdmin && (
        <Button onClick={toggleNewTransactionModal}>New transaction</Button>
      )}
    </InlineStack>
  );

  const transactionEmptyState = !transactions.length ? (
    <EmptyState
      heading="You don't have any transactions"
      action={
        isOrganizationAdmin
          ? {
              content: "New transaction",
              onAction: toggleNewTransactionModal,
            }
          : null
      }
      secondaryAction={{
        content: "Schedule transactions",
        disabled: !opportunity?.attributes?.installment_payment_start_date,
        onAction: () => {
          handleScheduleTransactions();
        },
      }}
      image={emptySearchCompanies}
    >
      <p>Add your first transaction to see them here.</p>
    </EmptyState>
  ) : undefined;

  const transactionsResourceList = (
    <ResourceList
      resourceName={transactionResourceName}
      items={transactions}
      renderItem={(item) => transactionResourceListItem(item)}
      selectedItems={transactionSelectedItems}
      onSelectionChange={setTransactionSelectedItems}
      loading={isLoadingTransactions}
      emptyState={transactionEmptyState}
      showHeader
      alternateTool={transactionResourceListAlternateTool}
      promotedBulkActions={transactionPromotedBulkActions}
    />
  );
  const toggleShowToast = useCallback(
    () => setShowToast((active) => !active),
    []
  );
  const handleDismissToast = () => {
    toggleShowToast();
    setToastMessage();
  };
  const toastMarkup = showToast ? (
    <Toast content={toastMessage} onDismiss={handleDismissToast} />
  ) : undefined;

  const [documentList, setDocumentList] = useState([]);
  const payProceedsDocumentList = [
    {
      id: "cit_loan_assignment",
      value: "cit_loan_assignment",
      label: "CIT Loan Assignment",
      title: "CIT Loan Assignment",
    },
    {
      id: "leaf_pay_proceeds",
      value: "leaf_pay_proceeds",
      label: "LEAF Pay Proceeds",
      title: "LEAF Pay Proceeds",
    },
    {
      id: "mitsubishi_pay_proceeds",
      value: "mitsubishi_pay_proceeds",
      label: "Mitsubshi Pay Proceeds",
      title: "Mitsubshi Pay Proceeds",
    },
  ];

  const initializeDocuments = () => {
    let pdfOptionList = [];

    for (let i = 0; i < opportunityAttachments.length; i++) {
      pdfOptionList.push({
        id: opportunityAttachments[i].id,
        value: opportunityAttachments[i],
        label: opportunityAttachments[i].attributes.name,
        title: opportunityAttachments[i].attributes.name,
      });
    }

    for (let i = 0; i < payProceedsDocumentList.length; i++) {
      pdfOptionList.push({
        id: payProceedsDocumentList[i].id,
        value: payProceedsDocumentList[i].value,
        label: payProceedsDocumentList[i].label,
        title: payProceedsDocumentList[i].title,
      });
    }

    if (vendorPayments.length > 0) {
      for (let i = 0; i < vendorPayments.length; i++) {
        if (
          opportunity?.attributes?.final_invoice_ids?.includes(
            vendorPayments[i].id
          ) &&
          vendorPayments[i].attributes.invoice_url &&
          vendorPayments[i].attributes.invoice_url?.length > 0
        ) {
          let finalInvoiceName =
            vendorPayments[i].attributes.vendor_name +
            " - " +
            vendorPayments[i].attributes.invoice_number;
          pdfOptionList.push({
            id: vendorPayments[i].id,
            value: finalInvoiceName,
            label: finalInvoiceName,
            title: finalInvoiceName,
          });
        }
      }
    }

    if (opportunity?.attributes?.subsidy) {
      pdfOptionList.push({
        id: "credit_memo",
        value: "credit_memo",
        label: "Credit Memo",
        title: "Credit Memo",
      });
    }

    setDocumentList(pdfOptionList);
  };

  useEffect(() => {
    if (opportunityAttachments) {
      initializeDocuments();
    }
  }, [opportunityAttachments, vendorPayments]);

  const PackageGenerateDocumentListItem = (props) => {
    const { id, index, title, deleteItem } = props;

    return (
      <Draggable draggableId={id} index={index}>
        {(provided, snapshot) => {
          return (
            <div
              className="FortifyDraggable__Item"
              ref={provided.innerRef}
              {...provided.draggableProps}
              style={
                snapshot.isDragging
                  ? {
                      background: "white",
                      listStyle: "none",
                      ...provided.draggableProps.style,
                    }
                  : { listStyle: "none", ...provided.draggableProps.style }
              }
            >
              <ResourceItem id={id}>
                <LegacyStack alignment="center">
                  <div {...provided.dragHandleProps}>
                    <Tooltip content="Drag to reorder list items">
                      <Icon source={DragHandleIcon} tone="inkLightest" />
                    </Tooltip>
                  </div>
                  <div
                    style={{ height: "20px", width: "20px" }}
                    onClick={() => {
                      deleteItem();
                    }}
                  >
                    <Icon source={XSmallIcon} tone="critical" />
                  </div>
                  <Thumbnail source={NoteIcon} size="small" />;
                  <Text as="h2">{title}</Text>
                </LegacyStack>
              </ResourceItem>
            </div>
          );
        }}
      </Draggable>
    );
  };

  const PackageGenerateDocumentList = () => {
    const handleDragEnd = useCallback(({ source, destination }) => {
      setDocumentList((oldItems) => {
        const newItems = oldItems.slice();
        const [temp] = newItems.splice(source.index, 1);
        newItems.splice(destination.index, 0, temp);
        return newItems;
      });
    }, []);

    const handleDelete = useCallback((item, index) => {
      setDocumentList(() => {
        let oldItems = JSON.parse(JSON.stringify(documentList));
        const newItems = oldItems.slice();
        newItems.splice(index, 1);
        return newItems;
      });
    }, []);

    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="root">
          {(provided) => {
            return (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {documentList.map((item, index) => (
                  <PackageGenerateDocumentListItem
                    key={item.id}
                    id={item.id}
                    index={index}
                    title={item.title}
                    deleteItem={() => {
                      handleDelete(item, index);
                    }}
                  />
                ))}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  };

  const [openGeneratePackageModal, setOpenGeneratePackageModal] =
    useState(false);
  const toggleOpenGeneratePackageModal = () => {
    setOpenGeneratePackageModal(!openGeneratePackageModal);
  };

  const [emailSummary, setEmailSummary] = useState("");
  const [emailSummaryModalVisible, setEmailSummaryModalVisible] =
    useState(false);
  const toggleEmailSummaryModalVisible = () => {
    setEmailSummaryModalVisible(!emailSummaryModalVisible);
  };

  const handleEmailSummaryChange = (value) => {
    setEmailSummary(value);
  };

  const copyEmailSummary = useCallback(() => {
    navigator.clipboard.writeText(emailSummary);
    setToastMessage("Summary email copied");
    toggleShowToast();
  }, [toggleShowToast, emailSummary]);

  const [loadingCombinedPdf, setLoadingCombinedPdf] = useState(false);

  const axiosCombinedPdf = async () => {
    let documentListQuery = "";
    for (let i = 0; i < documentList.length; i++) {
      if (documentListQuery === "") {
        documentListQuery = documentListQuery + documentList[i].id;
      } else {
        documentListQuery = documentListQuery + "," + documentList[i].id;
      }
    }

    const pathname = `/opportunities/${opportunityId}/combine_pdfs`;
    const params = { documents: documentListQuery };
    const result = await baseQuery()({
      url: pathname,
      method: "GET",
      data: {},
      params: params,
      responseType: "blob",
    });

    setLoadingCombinedPdf(false);
    toggleOpenGeneratePackageModal();

    let filename = `Funding Package - ${opportunity.attributes.client_name}`;
    let file = new File([result.data], `${filename}.pdf`, {
      type: "application/pdf",
    });
    let fileUrl = window.URL.createObjectURL(file);

    // If we want to serve but not download
    //window.open(fileUrl)

    let fileLink = document.createElement("a");
    fileLink.href = fileUrl;
    fileLink.download = `${filename}.pdf`;
    fileLink.click();
  };

  const handleCreateFundingPackage = () => {
    setLoadingCombinedPdf(true);
    axiosCombinedPdf();
  };

  const emailSummaryModal = (
    <Modal
      open={emailSummaryModalVisible}
      onClose={() => toggleEmailSummaryModalVisible()}
      title={"Summary Email"}
      primaryAction={{
        content: "Send",
        onAction: () => {
          setEmailSummaryModalVisible(false);
        },
      }}
      secondaryAction={{
        content: "Cancel",
        onAction: () => {
          toggleEmailSummaryModalVisible();
        },
      }}
    >
      <Modal.Section>
        <TextField
          label=""
          value={emailSummary}
          onChange={handleEmailSummaryChange}
          multiline={4}
          autoComplete="off"
        />
        <br />
        <Button onClick={copyEmailSummary}>Copy summary</Button>
      </Modal.Section>
    </Modal>
  );

  const generatePackageModal = openGeneratePackageModal && (
    <Modal
      open={openGeneratePackageModal}
      onClose={toggleOpenGeneratePackageModal}
      title={"Create funding package from the following documents"}
      primaryAction={{
        content: "Creating funding package",
        onAction: handleCreateFundingPackage,
        disabled: loadingCombinedPdf,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            initializeDocuments();
            toggleOpenGeneratePackageModal();
          },
        },
      ]}
    >
      {loadingCombinedPdf ? (
        <Modal.Section>
          <div
            style={{
              position: "relative",
              minHeight: "118px",
              width: "100%",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "32px",
                left: "calc(50% - 22px)",
              }}
            >
              <Spinner accessibilityLabel="Loading documents" />
            </div>
          </div>
        </Modal.Section>
      ) : (
        <Modal.Section flush>
          <PackageGenerateDocumentList />
        </Modal.Section>
      )}
    </Modal>
  );

  const fundingPackageEmptyState = (
    <EmptyState
      heading="Create and download a funding package"
      action={{
        content: "Create funding package",
        onAction: toggleOpenGeneratePackageModal,
      }}
      secondaryAction={{
        content: "Summary email",
        onAction: toggleEmailSummaryModalVisible,
      }}
      image={emptySearchCompanies}
    >
      <p>
        Create a pay proceeds letter, set the document order, and download as a
        single PDF
      </p>
    </EmptyState>
  );

  const validatePurchaseMethod = () => {
    validateStripeCustomerReference({
      contactId: designatedSignerId,
      vendorId: stripeVendorId,
    })
      .then(() => {
        setToastMessage("Payment method updated");
        setShowToast(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Schedule Transactions

  const [
    showConfirmScheduleTransactionsModal,
    setShowConfirmScheduleTransactionsModal,
  ] = useState(false);
  const toggleShowConfirmScheduleTransactionsModal = () => {
    setShowConfirmScheduleTransactionsModal(
      !showConfirmScheduleTransactionsModal
    );
  };

  const confirmScheduleTransactionsModal = (
    <Modal
      open={showConfirmScheduleTransactionsModal}
      onClose={toggleShowConfirmScheduleTransactionsModal}
      title="Confirm schedule transactions"
      primaryAction={{
        content: "Confirm",
        loading: isSchedulingTransactions,
        onAction: () => {
          callScheduleTransactions();
        },
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: toggleShowConfirmScheduleTransactionsModal,
        },
      ]}
    >
      <Modal.Section>
        <p>
          This will create or update incomplete transactions according to the
          selected schedule and installment payment start date. Completed
          transactions cannot be changed.
        </p>

        <br />

        <p>
          For help updating completed transactions &nbsp;
          <Link external monochrome url={`mailto:support@fortifypay.com`}>
            contact support
          </Link>
        </p>
      </Modal.Section>
    </Modal>
  );

  const handleScheduleTransactions = () => {
    // Reset error banner
    setShowTransactionErrorBanner(false);

    if (!opportunity.attributes.has_accepted_financing_option) {
      setShowTransactionErrorBanner(true);
      setTransactionErrorBannerMessage("No accepted purchase option selected");
      return;
    }

    toggleShowConfirmScheduleTransactionsModal();
  };

  const [
    scheduleOpportunityTransactions,
    { isLoading: isSchedulingTransactions },
  ] = useScheduleOpportunityTransactionsMutation();

  const callScheduleTransactions = () => {
    return scheduleOpportunityTransactions({
      opportunityId: opportunityId,
    })
      .unwrap()
      .then(() => {
        setShowScheduleTransactionPromptModal(false);
        refetchTransactions();
        setShowConfirmScheduleTransactionsModal(false);
        return { status: "success" };
      })
      .catch((error) => {
        setShowScheduleTransactionPromptModal(false);
        setShowConfirmScheduleTransactionsModal(false);
        console.log(error);
        setShowTransactionErrorBanner(true);
        setTransactionErrorBannerMessage(error.data);
      });
  };

  const [showTransactionErrorBanner, setShowTransactionErrorBanner] =
    useState(false);
  const toggleShowTransactionErrorBanner = () => {
    setShowTransactionErrorBanner(!showTransactionErrorBanner);
  };

  const [transactionErrorBannerMessage, setTransactionErrorBannerMessage] =
    useState("");
  const transactionErrorBanner = showTransactionErrorBanner && (
    <>
      <Banner
        title={transactionErrorBannerMessage}
        tone="critical"
        onDismiss={toggleShowTransactionErrorBanner}
      />
    </>
  );

  // Schedule Transaction Prompt

  const [
    showScheduleTransactionPromptModal,
    setShowScheduleTransactionPromptModal,
  ] = useState(false);
  const toggleShowScheduleTransactionPromptModal = () => {
    setShowScheduleTransactionPromptModal(!showScheduleTransactionPromptModal);
  };

  const checkIfScheduleTransactionPrompt = () => {
    if (opportunity?.attributes?.installment_payment_start_date) {
      return;
    }

    if (
      paymentMethods?.length > 0 &&
      opportunity?.attributes?.payments_authorized_at
    ) {
      setShowScheduleTransactionPromptModal(true);
    }
  };

  const scheduleTransactionPromptModal = (
    <Modal
      open={showScheduleTransactionPromptModal}
      onClose={toggleShowScheduleTransactionPromptModal}
      title="Schedule transactions"
      primaryAction={{
        content: "Confirm",
        loading: isSchedulingTransactions,
        onAction: () => {
          callScheduleTransactions();
        },
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: toggleShowScheduleTransactionPromptModal,
        },
      ]}
    >
      <Modal.Section>
        <p>
          Would you like to schedule transactions based on the installment
          payment start date?
        </p>
      </Modal.Section>
    </Modal>
  );

  const formattedPaymentScheduleRows =
    opportunity?.attributes?.payment_schedule?.map((payment, index) => {
      return [
        index + 1,
        payment.date
          ? formatNumericDate(convertServerDate(payment.date))
          : "--",
        payment.type,
        <TransactionBadge key={index} status={payment.status} />,
        formatCurrency(payment.amount),
      ];
    });

  const handlePreviewCustomerAmortizationDocs = () => {
    const document_name = "customer_amortization";
    const pathname = `/amortization_document_tests/${opportunityId}/${document_name}/pdf`;

    window.open(pathname, "_blank");
  };

  const handlePreviewFundingAmortizationDocs = () => {
    const document_name = "funding_amortization";
    const pathname = `/amortization_document_tests/${opportunityId}/${document_name}/pdf`;

    window.open(pathname, "_blank");
  };

  const paymentsAndReviewLayout = (
    <Layout>
      <Layout.Section>
        <BlockStack gap="400">
          {/* FINAL INVOICE CARD */}
          <Card padding="0">
            <CardBoxTitle>
              <Text variant="headingMd" as="h6">
                Invoice
              </Text>
            </CardBoxTitle>
            <FinalInvoiceList
              opportunity={opportunity}
              opportunityId={opportunityId}
              accountId={clientId}
            />
          </Card>

          {/* {isOrganizationAdmin && (
            <Card>
              <BlockStack gap="400">
                <Text variant="headingMd" as="h6">
                  Payment schedule DEMO FOR TESTING
                </Text>

                <DataTable
                  columnContentTypes={[
                    "text",
                    "text",
                    "text",
                    "text",
                    "numeric",
                  ]}
                  headings={["#", "Date", "Type", "Status", "Amount"]}
                  rows={formattedPaymentScheduleRows}
                  hasZebraStripingOnData
                  increasedTableDensity
                />
              </BlockStack>
            </Card>
          )} */}

          {/* TRANSACTIONS CARD */}
          {transactionErrorBanner}

          <Card padding="0">
            <CardBoxTitle>
              <Text variant="headingMd" as="h6">
                Transactions
              </Text>
            </CardBoxTitle>
            {transactionsResourceList}
          </Card>

          {/* OPPORTUNITY REVIEW CARD */}
          {isOrganizationAdmin && (
            <OpportunitiesReviewForm
              opportunityId={opportunityId}
              computeTransactions={computeTransactions}
            />
          )}

          {/* FUNDING PACKAGE CARD */}
          {isOrganizationAdmin && (
            <Card padding="0">
              <CardBoxTitle>
                <Text variant="headingMd" as="h6">
                  Funding package
                </Text>
              </CardBoxTitle>
              {fundingPackageEmptyState}
            </Card>
          )}

          {/* ATTACHMENTS CARD */}
          {/* <Card padding="0">{attachmentsResourceListLayout}</Card> */}
        </BlockStack>
      </Layout.Section>

      <Layout.Section variant="oneThird">
        <BlockStack gap="400">
          {/* TOTAL PAID TO VENDOR CARD */}
          <Card padding="0">
            <CardBoxTitle>
              <Text variant="headingMd" as="h6">
                Total paid to vendor
              </Text>
            </CardBoxTitle>
            <Box padding="400" as="section">
              <Text as="span" tone="subdued">
                Sum of all completed vendor payments
              </Text>
            </Box>
            <Box padding="400" as="section">
              <Text variant="headingXl" as="h4">
                {opportunity.attributes.all_vendor_payments > 0
                  ? formatCurrency(opportunity.attributes.all_vendor_payments)
                  : "-"}
              </Text>
            </Box>
          </Card>

          {/* INSTALLMENT PAYMENT SCHEDULE CARD */}
          <InstallmentPaymentScheduleCard
            opportunity={opportunity}
            opportunityId={opportunityId}
            transactions={transactions}
            handleScheduleTransactionPrompt={checkIfScheduleTransactionPrompt}
          />

          {/* PAYMENT METHOD CARD */}
          <Card padding="0">
            <CardBoxTitle>
              <Text variant="headingMd" as="h6">
                Customer payment method
              </Text>
            </CardBoxTitle>

            {paymentMethods.length === 0 &&
              !isLoadingStripeSetupIntent &&
              !isLoadingPaymentMethods &&
              !isFetchingPaymentMethods && (
                <Box padding="400" as="section">
                  <InlineStack>
                    <Text as="span" tone="subdued">
                      No payment method
                    </Text>
                  </InlineStack>
                </Box>
              )}

            {(isLoadingStripeSetupIntent ||
              isLoadingPaymentMethods ||
              isFetchingPaymentMethods) && (
              <Box padding="400" as="section">
                <InlineStack>
                  <Spinner
                    accessibilityLabel="Loading payment methods"
                    size={"small"}
                  />
                </InlineStack>
              </Box>
            )}

            {!isLoadingPaymentMethods &&
              !isLoadingStripeSetupIntent &&
              !isFetchingPaymentMethods &&
              /* paymentMethods.length > 0 && ( */
              allPaymentMethods.map((paymentMethod, index) => (
                <PaymentMethodCard
                  key={index}
                  allPaymentMethodsLength={allPaymentMethods.length}
                  validatePurchaseMethod={validatePurchaseMethod}
                  stripeSetupIntent={stripeSetupIntent}
                  opportunity={opportunity}
                  paymentMethod={paymentMethod}
                  index={index}
                />
              ))}
          </Card>

          {/* TOTAL PAID TO VENDOR CARD */}
          {isOrganizationAdmin && (
            <Card padding="0">
              <CardBoxTitle>
                <Text variant="headingMd" as="h6">
                  Amortization Documents
                </Text>
              </CardBoxTitle>
              <Box padding="400" as="section">
                {opportunity.attributes.commencement_date == undefined && (
                  <>
                    <Banner>
                      <p>
                        Funding and Customer Amortization documents are
                        unavailable because a Commencement Date has not yet been
                        set.
                      </p>
                    </Banner>
                    <br />
                  </>
                )}

                <InlineStack
                  gap={"400"}
                  wrap="false"
                  blockAlign={"center"}
                  align={"space-between"}
                >
                  <Text as="span">Customer Amortization</Text>

                  <Button
                    onClick={handlePreviewCustomerAmortizationDocs}
                    disabled={
                      opportunity.attributes.commencement_date == undefined
                    }
                  >
                    Preview
                  </Button>
                </InlineStack>

                <br />

                <InlineStack
                  gap={"400"}
                  wrap="false"
                  blockAlign={"center"}
                  align={"space-between"}
                >
                  <Text as="span">Funding Amortization</Text>

                  <Button
                    onClick={handlePreviewFundingAmortizationDocs}
                    disabled={
                      opportunity.attributes.commencement_date == undefined
                    }
                  >
                    Preview
                  </Button>
                </InlineStack>
              </Box>
            </Card>
          )}
        </BlockStack>
      </Layout.Section>

      {emailSummaryModal}
      {markCompleteModal}
      {newTransactionModal}
      {editTransactionModal}
      {generatePackageModal}
      {confirmScheduleTransactionsModal}
      {scheduleTransactionPromptModal}
      {toastMarkup}
    </Layout>
  );

  return paymentsAndReviewLayout;
};

export default OpportunitiesPaymentsAndReview;
