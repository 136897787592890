import React, { useState, useCallback } from "react";
import {
  Card,
  Page,
  Layout,
  FormLayout,
  InlineStack,
  Button,
  TextField,
  Select,
  BlockStack,
  Labelled,
  Tabs,
  List,
  Link,
  Text,
  Box,
} from "@shopify/polaris";
import { formatCurrency, validateCurrency } from "../utilities";

import fortLogo from "../assets/fort/logo.png";
import calculatorBackground from "../assets/calculatorBackground.jpeg";
import calculatorCTABanner from "../assets/calculatorCTABanner.png";

const Section179Calculator = () => {
  const [equipmentCostString, setEquipmentCostString] = useState("0");
  const [equipmentCost, setEquipmentCost] = useState("0");

  const handleEquipmentCostChange = useCallback((newValue) => {
    setEquipmentCostString(newValue);
    if (validateCurrency(newValue))
      setEquipmentCost(validateCurrency(newValue));
  }, []);

  const [taxBracket, setTaxBracket] = useState("35");

  const handleTaxBracketChange = useCallback(
    (value) => setTaxBracket(value),
    []
  );

  const taxBracketOptions = [
    { label: "10%", value: "10" },
    { label: "12%", value: "12" },
    { label: "21%", value: "21" },
    { label: "22%", value: "22" },
    { label: "24%", value: "24" },
    { label: "32%", value: "32" },
    { label: "35%", value: "35" },
    { label: "37%", value: "37" },
  ];

  const [firstYearDeduction, setFirstYearDeduction] = useState();
  const [cashSavings, setCashSavings] = useState();
  const [loweredCost, setLoweredCost] = useState();
  const calculateSavings = () => {
    setFirstYearDeduction(equipmentCost);
    const savings = equipmentCost * (taxBracket / 100);
    setCashSavings(savings);
    const cost = equipmentCost - savings;
    setLoweredCost(cost);
  };

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelectedTab(selectedTabIndex),
    []
  );

  const year = new Date().toLocaleString("en-US", {
    year: "numeric",
  });
  const lastYear = year - 1;

  const DEDUCTION_LIMIT = "$1,160,000";
  const PURCHASE_LIMIT = "$2,890,000";

  const deductionLimitMarkup = (
    <BlockStack gap={"400"}>
      <Text variant="headingMd" as="h2">
        Section 179 is meant for small and mid-sized companies
      </Text>
      <p>
        The Section 179 deduction limit for {year} is {DEDUCTION_LIMIT}, an
        $80,000 increase from last year. This means your company can deduct the
        full cost of qualifying equipment (new or used), up to {DEDUCTION_LIMIT}
        , from your {year} taxable income. This deduction is good until you
        reach {PURCHASE_LIMIT} (up from $2.7 million in {lastYear}). The
        deduction can include both new and used qualified equipment.
      </p>
      <p>
        In addition, businesses can take advantage of 80% bonus depreciation on
        both new and used equipment for the entirety of {year}. Remember to keep
        supply chain issues and delivery times in mind when making your Section
        179 purchases for {year}, as equipment must be purchased and put into
        service by midnight 12/31.
      </p>
      <Text variant="headingMd" as="h2">
        Section 179 deadline
      </Text>
      <p>
        The Section 179 deduction has proven wildly popular with small and
        mid-sized companies. But there is a deadline: to use the deduction for{" "}
        {year}, the equipment must be purchased/financed, and put into service
        by 11:59 pm, 12/31/{year}. To claim the deduction, use Form 4562.
      </p>
    </BlockStack>
  );

  const qualifyingPropertyMarkup = (
    <BlockStack gap="400">
      <List type="bullet">
        <List.Item>
          Most tangible business equipment (both new and used) will qualify for
          the deduction. This includes production machinery, computers/printers,
          office furniture, copiers and other office machines, signage,
          shelving, storage tanks, and most other general equipment.
        </List.Item>
        <List.Item>
          Commercial use vehicles will usually qualify. This means vehicles with
          seating for 9+ passengers, a 6’ cargo area, or a fully enclosed
          cab-over driving compartment.
        </List.Item>
        <List.Item>
          Passenger vehicles exceeding 6,000+ pounds GVW (gross vehicle weight)
          will usually qualify, however, they are usually limited to a $25,000
          deduction.
        </List.Item>
        <List.Item>“Heavy equipment” qualifies.</List.Item>
        <List.Item>
          Section 179 is good for software as well. The caveat is the software
          must be available for purchase by the general public, and carry a
          non-exclusive license, without custom modifications. This means
          operating systems, common office suites, common graphics programs, and
          similar will qualify. This does not include websites and/or apps.
        </List.Item>
        <List.Item>
          Many storage structures (such as those used for agricultural purposes)
          will qualify. This will not include your primary building.
        </List.Item>
        <List.Item>
          Many building improvements like HVAC, alarm and/or fire systems,
          roofs, and similar can qualify.
        </List.Item>
        <List.Item>
          Used/preowned versions of the preceding (save for software of course)
          will generally qualify for Section 179.
        </List.Item>
        <List.Item>
          If you have questions regarding Section 179 eligibility of equipment
          you wish to lease or finance, you can always contact us, or reference
          IRS Publication 946.
        </List.Item>
      </List>
      <p>
        The equipment listed above need not be new – it can be used (but new to
        you). Almost any &quot;portable&quot; (non-permanently installed) piece
        of business equipment will likely qualify. If you have any questions on
        whether something you wish to lease or finance will qualify for Section
        179, you can always{" "}
        <Link external url={"https://fortcapitalresources.com/"}>
          ask us
        </Link>
        , or reference{" "}
        <Link external url={"http://www.irs.gov/pub/irs-pdf/p946.pdf"}>
          IRS Publication 946
        </Link>
        .
      </p>
    </BlockStack>
  );

  const tabs = [
    {
      id: "deduction-limits",
      content: "Deduction limits",
      accessibilityLabel: "Deduction limits",
      panelID: "deduction-limits-content",
      markup: deductionLimitMarkup,
    },
    {
      id: "qualifying-property",
      content: "Qualifying property",
      accessibilityLabel: "Qualifying property",
      panelID: "qualifying-property-content",
      markup: qualifyingPropertyMarkup,
    },
  ];

  return (
    <>
      <Page>
        <Layout>
          <Layout.Section>
            <BlockStack gap={"800"}>
              <InlineStack alignment={"center"} distribution={"equalSpacing"}>
                <div>
                  <img
                    src={fortLogo}
                    className={"FortCalculator__PublicBrandImage"}
                  />
                </div>
              </InlineStack>

              <Text variant="heading2xl" as="p">
                <Text as="span" fontWeight="semibold">
                  How The Section 179 Deduction Benefits You
                </Text>
              </Text>
              <div
                className={"FortCalculator__Banner"}
                style={{ backgroundImage: `url(${calculatorBackground})` }}
              ></div>
            </BlockStack>
          </Layout.Section>

          <Layout.Section>
            <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}>
              <Box
                paddingBlockStart="400"
                paddingInlineStart={"400"}
                paddingInlineEnd={"400"}
              >
                <BlockStack gap={"400"}>{tabs[selectedTab].markup}</BlockStack>
              </Box>
            </Tabs>
          </Layout.Section>

          <Layout.Section>
            <Card title={`${year} Section 179 Tax Deduction Calculator`}>
              <BlockStack gap={"400"}>
                <FormLayout>
                  <TextField
                    label="Cost of equipment"
                    type="currency"
                    value={equipmentCostString}
                    autoComplete="off"
                    onChange={handleEquipmentCostChange}
                    prefix={"$"}
                    suffix={".00"}
                  />

                  <Select
                    label="Estimated tax bracket"
                    options={taxBracketOptions}
                    onChange={handleTaxBracketChange}
                    value={taxBracket}
                  />

                  <Button
                    fullWidth
                    onClick={calculateSavings}
                    variant="primary"
                  >
                    Show My Savings
                  </Button>
                </FormLayout>

                <FormLayout>
                  <Labelled label="Total first year deduction">
                    <div
                      style={{
                        minHeight: "36px",
                        paddingTop: "4px",
                      }}
                    >
                      <InlineStack alignment="trailing" spacing="tight">
                        <InlineStack alignment="center" spacing="tight">
                          <Text variant="headingLg" as="p">
                            {formatCurrency(firstYearDeduction)}
                          </Text>
                        </InlineStack>
                      </InlineStack>
                    </div>
                  </Labelled>

                  <Labelled label="Cash savings on purchase">
                    <div
                      style={{
                        minHeight: "36px",
                        paddingTop: "4px",
                      }}
                    >
                      <InlineStack alignment="trailing" spacing="tight">
                        <InlineStack alignment="center" spacing="tight">
                          <Text variant="headingLg" as="p">
                            {formatCurrency(cashSavings)}
                          </Text>
                        </InlineStack>
                      </InlineStack>
                    </div>
                  </Labelled>
                </FormLayout>

                <FormLayout>
                  <Labelled label="Lowered cost of equipment (After tax savings)">
                    <div
                      style={{
                        minHeight: "36px",
                        paddingTop: "4px",
                      }}
                    >
                      <InlineStack alignment="trailing" spacing="tight">
                        <InlineStack alignment="center" spacing="tight">
                          <Text variant="headingXl" as="p">
                            <Text as="span" tone="success">
                              {formatCurrency(loweredCost)}
                            </Text>
                          </Text>
                        </InlineStack>
                      </InlineStack>
                    </div>
                  </Labelled>
                </FormLayout>

                <p>
                  <Text as="span" tone="subdued">
                    The calculator presents a potential tax scenario based on
                    typical assumptions that may not apply to your business.
                    This page and calculator are not tax advice. The indicated
                    tax treatment applies only to transactions deemed to reflect
                    a purchase of the equipment or a capitalized lease purchase
                    transaction. Please consult your tax advisor to determine
                    the tax ramifications of acquiring equipment or software for
                    your business.
                  </Text>
                </p>
              </BlockStack>
            </Card>
          </Layout.Section>

          <Layout.Section>
            <BlockStack gap={"400"}>
              <Text variant="heading2xl" as="p">
                About FORT Capital Resources, Inc.
              </Text>
              <p>
                FORT is an integrated finance platform for manufacturers,
                resellers, and their clients. We bring together finance, sales,
                operations, and accounting on one platform to make financing
                your customer transactions seamless, transparent, and fast.
                FORT’s platform integrates financing options into the buying
                flow, impacting all of your business functions, from the
                board/executive level to operations and accounting.
              </p>
            </BlockStack>
          </Layout.Section>

          <Layout.Section>
            <InlineStack distribution={"center"}>
              <Link
                url={
                  "https://cta-redirect.hubspot.com/cta/redirect/21154079/5dba708f-b401-4f03-8249-ca1b99ec2829"
                }
                external
              >
                <img
                  src={calculatorCTABanner}
                  className={"FortCalculator__CTABanner"}
                />
              </Link>

              {/* <div>
                <a href="https://cta-redirect.hubspot.com/cta/redirect/21154079/5dba708f-b401-4f03-8249-ca1b99ec2829">
                  <img
                    className={"FortCalculator__CTABanner"}
                    src="https://no-cache.hubspot.com/cta/default/21154079/5dba708f-b401-4f03-8249-ca1b99ec2829.png"
                  />
                </a>
              </div> */}

              <div>
                <a href="https://cta-redirect.hubspot.com/cta/redirect/21154079/ae618084-9996-42c6-bce9-d3062cf205c8">
                  <img
                    className={"FortCalculator__CTABanner"}
                    src="https://no-cache.hubspot.com/cta/default/21154079/ae618084-9996-42c6-bce9-d3062cf205c8.png"
                  />
                </a>
              </div>
            </InlineStack>
          </Layout.Section>
        </Layout>
      </Page>

      <div
        style={{
          background: "white",
          width: "100vw",
          marginLeft: "calc(-50vw + 50%)",
          marginTop: "32px",
          padding: "32px",
          boxShadow: "0 50vh 0 50vh #fff",
        }}
      >
        <Page>
          <Layout>
            <Layout.Section>
              <BlockStack align={"center"} inlineAlign={"center"} gap={"400"}>
                <Text variant="bodySm" as="p">
                  powered by
                </Text>
                <Link url="https://www.fortcapitalresources.com" external>
                  <img src={fortLogo} style={{ maxHeight: "40px" }} />
                </Link>
              </BlockStack>
            </Layout.Section>
          </Layout>
        </Page>
      </div>
    </>
  );
};

export default Section179Calculator;
