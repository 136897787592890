import React, { useState, useEffect } from "react";

import {
  Text,
  Button,
  Card,
  BlockStack,
  InlineStack,
  Badge,
  Box,
} from "@shopify/polaris";

import { useUpdateFinancingOptionMutation } from "../../services/api";
import { useToast } from "../../contexts/Toast";

type Props = {
  purchaseOptionPaymentsEnabled: boolean;
  purchaseOptionId: string;
  opportunityId: string;
};

const PaymentsEnabledToggle = (props: Props) => {
  const { purchaseOptionPaymentsEnabled, purchaseOptionId, opportunityId } = props;

   const { showToastWithMessage } = useToast();

  const [updateFinancingOption, { isLoading: isUpdatingFinancingOption }] =
    useUpdateFinancingOptionMutation();
    
  const title = "Payments Enabled"

  const [paymentsEnabled, setPaymentsEnabled] = useState(true);

  useEffect(() => {
    setPaymentsEnabled(purchaseOptionPaymentsEnabled)
  }, [purchaseOptionPaymentsEnabled])

  const handlePaymentsEnabled = () => {
    setPaymentsEnabled(!paymentsEnabled)

    updateFinancingOption({
      id: purchaseOptionId,
      opportunityId: opportunityId,
      payments_enabled: !paymentsEnabled,
    })
      .then(() => {
        showToastWithMessage(`Payments ${!paymentsEnabled ? "enabled" : "disabled"}`)
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const actionMarkup = (
    <Button
      role="switch"
      onClick={handlePaymentsEnabled}
      loading={isUpdatingFinancingOption}
      size="slim"
    >
      {paymentsEnabled ? "Disable Payments" : "Enable Payments"}
    </Button>
  );

  const titleMarkup = (
  <InlineStack gap="200" wrap={false}>
      <InlineStack gap="200" align="start" blockAlign="baseline">
        <label>
          <Text variant="headingMd" as="h6">
            {title}
          </Text>
        </label>
        <InlineStack gap="200" align="center" blockAlign="center">
          <Badge tone={paymentsEnabled ? 'success' : 'critical'}>
            {paymentsEnabled ? "On" : "Off"}
          </Badge>
        </InlineStack>
      </InlineStack>
    </InlineStack>
  )

  const description =
    "Enabling this setting will allow ACH payments for this purchase option, should other conditions such as enabled Stripe payments are fulfilled.";

  const descriptionMarkup = (
    <BlockStack gap="400">
      <Text variant="bodyMd" as="p" tone="subdued">
        {description}
      </Text>
    </BlockStack>
  );

  return (
    <Card>
      <BlockStack gap={{ xs: "400", sm: "500" }}>
        <Box width="100%">
          <BlockStack gap={{ xs: "200", sm: "400" }}>
            {titleMarkup}
            {descriptionMarkup}
          </BlockStack>
        </Box>

        <InlineStack gap="400" align={"space-between"} blockAlign={"center"}>
          <Text variant="bodyMd" as="p">
            Payments are
            <b>{paymentsEnabled ? " enabled " : " disabled "}</b>
            for this purchase option
          </Text>

          <Box>
            <InlineStack align="start">{actionMarkup}</InlineStack>
          </Box>
        </InlineStack>
      </BlockStack>
    </Card>
  );
};

export default PaymentsEnabledToggle;
