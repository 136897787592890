let nestedMenu = [
  "Utilities",
  "Opportunity",
  "Docusign",
  "Client",
  "Designated Signer",
  "Organization Signer",
];

let subMenuOpportunity = [
  {
    text: "APR",
    insertContent: "&nbsp; {{ opportunity.apr }} &nbsp;",
  },
  {
    text: "Amount Provided to You or On Your Behalf",
    insertContent:
      "&nbsp; {{ opportunity.amount_provided_to_customer }} &nbsp;",
  },
  {
    text: "Advance Payments Total",
    insertContent: "&nbsp; {{ opportunity.advance_payments_total }} &nbsp;",
  },
  {
    text: "Advance Total Collected",
    insertContent: "&nbsp; {{ opportunity.advance_total_collected }} &nbsp;",
  },
  {
    text: "Agreement Number",
    insertContent: "&nbsp; {{ opportunity.agreement_number }} &nbsp;",
  },
  {
    text: "Agreement Type",
    insertContent: "&nbsp; {{ opportunity.agreement_type }} &nbsp;",
  },
  {
    text: "Base Payment",
    insertContent: "&nbsp; {{ opportunity.base_payment }} &nbsp;",
  },
  {
    text: "Blind Discount - $ Amount",
    insertContent: "&nbsp; {{ opportunity.blind_discount_amount }} &nbsp;",
  },
  {
    text: "Blind Discount - %",
    insertContent: "&nbsp; {{ opportunity.blind_discount_percentage }} &nbsp;",
  },
  {
    text: "Booked Date",
    insertContent: "&nbsp; {{ opportunity.booked_date }} &nbsp;",
  },
  {
    text: "Commencement Date",
    insertContent: "&nbsp; {{ opportunity.commencement_date }} &nbsp;",
  },
  {
    text: "Document Name",
    insertContent: "&nbsp; {{ opportunity.document_name }} &nbsp;",
  },
  {
    text: "Documentation Fee",
    insertContent: "&nbsp; {{ opportunity.documentation_fee }} &nbsp;",
  },
  {
    text: "Install Date",
    insertContent: "&nbsp; {{ opportunity.install_date }} &nbsp;",
  },
  {
    text: "Installment Payment",
    insertContent: "&nbsp; {{ opportunity.installment_payment }} &nbsp;",
  },
  {
    text: "Quote Numbers",
    insertContent: "&nbsp; {{ opportunity.invoice_numbers }} &nbsp;",
  },
  {
    text: "Net Payment",
    insertContent: "&nbsp; {{ opportunity.net_payment }} &nbsp;",
  },
  {
    text: "Payment Frequency",
    insertContent: "&nbsp; {{ opportunity.payment_frequency }} &nbsp;",
  },
  {
    text: "Payment Schedule Note",
    insertContent: "&nbsp; {{ opportunity.payment_schedule_note }} &nbsp;",
  },
  {
    text: "Payments Total including Tax",
    insertContent:
      "&nbsp; {{ opportunity.payments_total_including_tax }} &nbsp;",
  },
  {
    text: "Product Address",
    insertContent: "&nbsp; {{ opportunity.product_address }} &nbsp;",
  },
  {
    text: "Product Description",
    insertContent: "&nbsp; {{ opportunity.product_description }} &nbsp;",
  },
  {
    text: "Term Length",
    insertContent: "&nbsp; {{ opportunity.term_length }} &nbsp;",
  },
  {
    text: "Total Deal Fees",
    insertContent: "&nbsp; {{ opportunity.total_fees }} &nbsp;",
  },
  {
    text: "Total Finance Charge",
    insertContent: "&nbsp; {{ opportunity.total_finance_charge }} &nbsp;",
  },
  {
    text: "Total Finance Amount",
    insertContent: "&nbsp; {{ opportunity.total_finance_amount }} &nbsp;",
  },
  {
    text: "Total Quote Amount",
    insertContent: "&nbsp; {{ opportunity.total_invoice_amount }} &nbsp;",
  },
  {
    text: "Total Sales Tax",
    insertContent: "&nbsp; {{ opportunity.total_sales_tax }} &nbsp;",
  },
  {
    text: "Vendor List",
    insertContent: "&nbsp; {{ opportunity.vendor_list }} &nbsp;",
  },
];

let subMenuDocusign = [
  {
    text: "Text",
    insertContent: "&nbsp; {{ docusign.text }} &nbsp;",
  },
];

let subMenuClient = [
  {
    text: "Address",
    insertContent: "&nbsp; {{ client.address }} &nbsp;",
  },
  {
    text: "DBA",
    insertContent: "&nbsp; {{ client.dba }} &nbsp;",
  },
  {
    text: "Name",
    insertContent: "&nbsp; {{ client.name }} &nbsp;",
  },
  {
    text: "Phone",
    insertContent: "&nbsp; {{ client.phone }} &nbsp;",
  },
  {
    text: "Tax ID",
    insertContent: "&nbsp; {{ client.tax_id }} &nbsp;",
  },
];

let subMenuDesignatedSigner = [
  {
    text: "Name",
    insertContent: "&nbsp; {{ designated_signer.name }} &nbsp;",
  },
  {
    text: "Signature",
    insertContent: "&nbsp; {{ designated_signer.signature }} &nbsp;",
  },
  {
    text: "Signing Date",
    insertContent: "&nbsp; {{ designated_signer.signing_date }} &nbsp;",
  },
  {
    text: "Inline Signing Date",
    insertContent: "&nbsp; {{ designated_signer.signing_date_inline }} &nbsp;",
  },
  {
    text: "Title",
    insertContent: "&nbsp; {{ designated_signer.title }} &nbsp;",
  },
];

let subMenuOrganizationSigner = [
  {
    text: "Name",
    insertContent: "&nbsp; {{ organization_signer.name }} &nbsp;",
  },
  {
    text: "Signature",
    insertContent: "&nbsp; {{ organization_signer.signature }} &nbsp;",
  },
  {
    text: "Signing Date",
    insertContent: "&nbsp; {{ organization_signer.signing_date }} &nbsp;",
  },
  {
    text: "Title",
    insertContent: "&nbsp; {{ organization_signer.title }} &nbsp;",
  },
];

let subMenuUtilities = [
  {
    text: "Today",
    insertContent: "&nbsp; {{ utilities.today }} &nbsp;",
  }
]

let submenuItems = {
  Utilities: subMenuUtilities,
  Opportunity: subMenuOpportunity,
  Docusign: subMenuDocusign,
  Client: subMenuClient,
  "Designated Signer": subMenuDesignatedSigner,
  "Organization Signer": subMenuOrganizationSigner,
};

function editorSnippets(editor) {
  var items = [];

  for (let i = 0; i < nestedMenu.length; i++) {
    let currentMenu = nestedMenu[i];

    let subMenu = [];
    for (let j = 0; j < submenuItems[currentMenu].length; j++) {
      let subMenuItem = {
        type: "menuitem",
        text: submenuItems[currentMenu][j].text,
        onAction: function () {
          editor.insertContent(submenuItems[currentMenu][j].insertContent);
        },
      };

      subMenu.push(subMenuItem);
    }

    let menu = {
      type: "nestedmenuitem",
      text: currentMenu,
      getSubmenuItems: function () {
        return subMenu;
      },
    };

    items.push(menu);
  }

  return items;
}

function htmlSnippets(insertLiquidSnippet) {
  var items = [];

  for (let i = 0; i < nestedMenu.length; i++) {
    let currentMenu = nestedMenu[i];

    let subMenu = [];
    for (let j = 0; j < submenuItems[currentMenu].length; j++) {
      let subMenuItem = {
        content: submenuItems[currentMenu][j].text,
        onAction: () => {
          insertLiquidSnippet(submenuItems[currentMenu][j].insertContent);
        },
      };

      subMenu.push(subMenuItem);
    }

    let menu = {
      title: currentMenu,
      items: subMenu,
    };

    items.push(menu);
  }

  return items;
}

export { editorSnippets, htmlSnippets };
